import React, { useEffect, useState } from "react";
import Notification from "../../components/Notification";
import profile from "../../assets/images/profile.png";
import folder from "../../assets/images/icons/folder.svg";
import { ApiRequests } from "../../service/ApiRequests";
import down from "../../assets/images/icons/down-arrow.svg";
import moment from "moment";
export default function InboxPage() {
  const [notifications, setNotifications] = useState([]);
  useEffect(() => {
    ApiRequests.getNotifications().then((res) => setNotifications(res.data));
  }, []);
  return (
    <div className="projects-page h-100">
      {/* <Notification /> */}
      {notifications.length ? (
        <div className="container-fluid">
          <div className="task-table-container">
            <div className="inbox_screen">
              <h6>Notifications</h6>
              <div className="notifications_box">
                <p>2024</p>
                <div className="monthly_box">
                  <div className="status-icon">
                    This week
                  </div>
                  <hr />
                  <div className="icon_img">
                    <img src={down} alt="down arrow" className="icon_box" />
                  </div>
                </div>
              </div>

              <div style={{ display: "flex", flexDirection: "column" }}>
                {notifications.map((notification) => (
                  <>
                    <div className="flex_box">
                      <div className="line">
                        <div className="img_box">
                          <img src={folder} alt="down arrow" className="icon_box" />
                        </div>
                      </div>
                      <div className="flex-grow-1">
                        <div className="name_box">
                          <div className="d-flex gap-2">
                            <img src={notification?.event?.user?.photoURL || profile} alt="profile" className="icon_box" />
                            <h6>
                              {notification?.event?.description.replace('usernameReplaceHere', notification?.event?.user?.displayName || notification?.event?.user?.firstName + " " + notification?.event?.user?.lastName)}
                            </h6>
                          </div>
                          <p>{moment(notification?.event?.createdAt).fromNow()}</p>
                        </div>
                        <div className="inbox_content">
                <h6>Discovery notes</h6>
                <p>element</p>
              </div>
                      </div>
                    </div>
                  </>
                ))}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Notification />
      )}
    </div>
  );
}
