import React from "react";
import img2 from "../assets/images/icons/img2.png";
import img3 from "../assets/images/icons/img3.png";
import img6 from "../assets/images/icons/img6.png";
import img7 from "../assets/images/icons/img7.png";
import img11 from "../assets/images/icons/logo-circular.png";
import img13 from "../assets/images/icons/img13.png";
import img14 from "../assets/images/icons/img14.png";
import img15 from "../assets/images/icons/img15.png";
import img16 from "../assets/images/icons/img16.png";
import Topbar from "../components/Topbar";
import medical from '../assets/images/icons/medical icon.svg';
import home from '../assets/images/icons/home.svg';
import { GrAttachment } from "react-icons/gr";
import mobile from '../assets/images/icons/carbon_mobile-session.svg';
import emoji from '../assets/images/icons/Button - Pick emoji.svg';
import mention from '../assets/images/icons/Button - Mention.svg';

const ClientDetail = () => {
    return (
        <>
            <div className='client-detail-wrapper'>
                <div className="projects-detail-page">
                    <Topbar />

                    <div className="container-fluid">
                        {/* Tabs Navigation */}
                        <div className="tabs-container">
                            <div className="tab-item">
                                <img src={img2} alt="Details Icon" />
                                Details
                            </div>
                            <div className="tab-item">
                                <img src={img13} alt="Notes Icon" />
                                Sessions
                            </div>
                            <div
                                className='tab-item'>
                                <img src={img14} alt="Tasks Icon" />
                                Files
                            </div>
                        </div>

                        {/* Content for Each Tab */}

                        <div className="tab-content pb-0">
                            <div className="details-tab project-detail-tabs-wrapper">

                                <div className="content-div mt-4">
                                    <div className="content">
                                        <img className="coment-icon" src={img3} alt="Name Icon" />
                                        <p>Name</p>
                                    </div>
                                    <h1 className="heading">haseeb</h1>
                                </div>
                                <div className="content-div">
                                    <div className="content">
                                        <img src={medical} alt="Status Icon" />
                                        <p>Medical ID</p>
                                    </div>
                                    <div className="content-detail">
                                        <p>34567891A</p>
                                    </div>
                                </div>
                                <div className="content-div">
                                    <div className="content">
                                        <img src={home} alt="Priority Icon" />
                                        <p>Address</p>
                                    </div>
                                    <div className="content-detail">
                                        <p>123 Maple St, MN</p>
                                    </div>
                                </div>
                                <div className="content-div">
                                    <div>
                                        <div className="content">
                                            <img src={img6} alt="Assignee Icon" />
                                            <p>Assigned Staff</p>
                                        </div>
                                    </div>
                                    <div className="avatars-wrapper">
                                        <div className="single-avatar">
                                            <img
                                                src={img11}
                                                alt="Assignee"
                                                style={{
                                                    width: "24px",
                                                    height: "24px",
                                                    borderRadius: "50%",
                                                    marginRight: "5px",
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="content-div">
                                    <div className="content">
                                        <img src={img7} alt="Due Date Icon" />
                                        <p>Due Date</p>
                                    </div>
                                    <p>
                                        <span className="date">
                                            October 28, 2024
                                        </span>
                                    </p>
                                </div>

                                <div className="content-div">
                                    <div className="content">
                                        <img src={mobile} alt="Priority Icon" />
                                        <p>Session Count</p>
                                    </div>
                                    <div className="content-detail">
                                        <p>5</p>
                                    </div>
                                </div>

                                <div className="content-div mb-2">
                                    <div className="content">
                                        <GrAttachment />
                                        <p>File Count</p>
                                    </div>
                                    <div className="content-detail">
                                        <p>1</p>
                                    </div>
                                </div>

                            </div>
                            <div className="bootom detail_bootom"></div>
                            <div>
                                <h3 className="comments">
                                    <img src={img16} alt="Comments Icon" /> Comments
                                    <span className="notes"></span>
                                </h3>
                            </div>

                            <div className="com-main">
                                <div className="comment-sec">
                                    <img className="com-img mt-1" src={img11} alt="User Avatar" />

                                    <form className="flex-grow-1">
                                        <textarea
                                            className="com-head2"
                                            // type="text"
                                            placeholder="Add comment...."
                                            rows="1"
                                        />
                                    </form>
                                </div>
                                <div className="but-main">
                                    <div className="emojibtn">
                                        <button>
                                            <img src={emoji} alt="Emoji" />
                                        </button>
                                        <button>
                                            <img src={mention} alt="mention" />
                                        </button>
                                    </div>
                                    <button className="sign-in">
                                        Comment
                                    </button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ClientDetail
