// ** Reducers Imports
import auth from "./pagesSlices/authSlice";
import model from "./layoutSlices/modelSlice";
import user from "./pagesSlices/userSlice";
import workspace from "./pagesSlices/workspaceSlice.js";
import crudExampleSlice from "./pagesSlices/crudExampleSlice.js";
import error from "./errors/handleErrorsAndPayloads";
import { combineReducers } from "@reduxjs/toolkit";
import task from "./pagesSlices/taskSlice.js";
import doc from "./pagesSlices/docSlice.js";
import comment from './pagesSlices/commentSlice.js'
import notes from './pagesSlices/noteSlice.js'
import project from './pagesSlices/projectSlice.js'
import client from './pagesSlices/clintSlice.js'
const rootReducer = combineReducers({
  user,
  error,
  auth,
  model,
  workspace,
  crudExampleSlice,
  task,
  doc,
  comment,
  notes,
  project,
  client,
});

export default rootReducer;
