import React from 'react'
import docx from "../assets/images/icons/docx.svg";
import Image from "../assets/images/Image.png";
import { BsThreeDotsVertical } from "react-icons/bs";
import Dropdown from "react-bootstrap/Dropdown";
import del from '../assets/images/icons/del.svg';
import ClintTopBar from '../components/ClintTopBar';

const ClientFile = () => {
  return (
    <>
    <ClintTopBar />
      <div className='client-file-wrapper'>
      <div className="docs-table-list h-100">
      <div className="task-table-container h-100">
          <table className="task-table docs-table">
            <thead className="heading_box">
              <tr>
                <th>Documents</th>
                <th>Uploaded By</th>
                <th>Date Uploaded</th>
              </tr>
            </thead>
            <tbody>
                <tr>
                  <td>
                    <div className="icon-wrapper">
                      <img src={docx} alt="document" />
                      <p>Session Note </p>
                    </div>
                  </td>
                  <td className="assignee">
                    <img src={Image} alt="assignee" />
                  </td>
                  <td>
                    <div className="icon-wrapper">
                      <span>11/27/2024</span>

                      <Dropdown>
                      <Dropdown.Toggle style={{ background: "transparent", border: "none", color: "black" }}
                        id="dropdown-basic"
                        className="custom-dropdown-toggle"
                      >
<BsThreeDotsVertical />
</Dropdown.Toggle>

                      <Dropdown.Menu className="delete-button">
                        <Dropdown.Item href="#/action-1">
                          <img src={del} alt="delete" />
                          <span>Delete </span>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                    </div>
                  </td>
                </tr>
            </tbody>
          </table>
      </div>
    </div>
      </div>
    </>
  )
}

export default ClientFile
