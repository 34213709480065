// TaskTable.jsx
import React from "react";
import docx from "../assets/images/icons/docx.svg";
import Image from "../assets/images/Image.png";
import NodataFound from "./NodataFound";
import { useNavigate } from "react-router-dom";

const DocsTable = ({ headerIcon, headerTitle, assignDocuments }) => {
  const navigate = useNavigate()
  const formatDate = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    const options = { year: "numeric", month: "long", day: "numeric" };
    return date.toLocaleDateString("en-US", options);
  };

  return (
    <div className="docs-table-list h-100">
      <div className="task-table-container h-100">
        {/* <Button className="btn-style" onClick={() => setModalShow(true)}>Crete doc</Button> */}

        {assignDocuments?.results.length > 0 ?
          <table className="task-table docs-table">
            <thead>
              <tr style={{ backgroundColor: "#F0F0F0" }}>
                <th
                  style={{ display: "flex", alignItems: "center", gap: "10px" }}
                >
                  <img src={headerIcon} alt="" />
                  <span style={{ fontSize: "13px", fontWeight: "500" }}>
                    {headerTitle}
                  </span>
                </th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <thead className="heading_box">
              <tr>
                <th>Documents</th>
                <th>Uploaded By</th>
                <th>Date Uploaded</th>
              </tr>
            </thead>
            <tbody>
              {assignDocuments?.results?.map((item, idx) => (
                <tr key={idx}>
                  <td>
                    <div className="icon-wrapper">
                      <img src={docx} alt="document" />
                      <p>{item?.name.split("-").pop()}</p>
                    </div>
                  </td>
                  <td className="assignee">
                    <img src={Image} alt="assignee" />
                  </td>
                  <td>
                    <div className="icon-wrapper">
                      <span>{formatDate(item?.createdAt)}</span>

                      {/* <Dropdown>
                      <Dropdown.Toggle style={{ background: "transparent", border: "none", color: "black" }}
                        id="dropdown-basic"
                        className="custom-dropdown-toggle"
                      >
                        <img src={threedot} alt="threedot" />
                      </Dropdown.Toggle>

                      <Dropdown.Menu className="delete-button">
                        <Dropdown.Item href="#/action-1" onClick={handleShow}>
                          <img src={del} alt="delete" />
                          <span>Delete </span>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown> */}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table> :
          <NodataFound title="File" navigateHandler={() => { navigate("/projects") }} />
        }

        {/* <DeleteModal show={show} handleClose={handleClose} /> */}

        {/* Modal to upload document */}
        {/* <Modal
          show={modalShow}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          onHide={() => setModalShow(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Create Document
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="formFile" className="mb-3">
                <Form.Label>Select Document to Upload</Form.Label>
                <Form.Control
                  type="file"
                  onChange={handleFileChange}
                  accept=".docx, .pdf, .txt" // Restrict file types as needed
                />
              </Form.Group>
              <Button variant="primary" type="submit">
                Upload
              </Button>
            </Form>
          </Modal.Body>
        </Modal> */}
      </div>
    </div>
  );
};

export default DocsTable;
