import React, { useState } from "react";
import { LuKey } from "react-icons/lu";
import SignUpFooter from "../components/SignUpFooter";
import LogoBar from "../components/LogoTopBar";
import { Link } from "react-router-dom";
import emailIcon from "../assets/images/icons/email-icon.svg";
import { useSignIn } from "@clerk/clerk-react";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";
import mailIcon from "../assets/images/icons/mail-icon.svg";

function ForgetPassword() {
  const { signIn } = useSignIn();
  const [email, setEmail] = useState("");
  const [isLinkSent, setIsLinkSent] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      let result = await signIn.create({
        identifier: email,
        strategy: "reset_password_email_code",
        redirectUrl: `${process.env.REACT_APP_FRONTEND_URL}/reset-password`,
      });
      if (isLinkSent) {
        toast.success("Reset password code sent successfully");
      }
      setIsLinkSent(true);
    } catch (error) {
      setIsLinkSent(false);
      console.error(error.errors);

      if (error.message) {
        toast.error(error.message);
        throw error.message;
      } else if (error.errors) {
        const messages = error.errors
          .map((err) => {
            if (err.code === "form_conditional_param_value_disallowed") {
              return "The account was registered using Google, so it's not allowed to reset the password.";
            }
            return err.message;
          })
          .join(", ");

        toast.error(messages);
      } else {
        toast.error("An unexpected error occurred. Please try again.");
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="home-page">
      <LogoBar />
      {isLinkSent ? (
        <div className="container my-5">
          <div className="check-email-wrapper">
            <div className="key-icon-wrapper">
              <div className="box">
                <img src={mailIcon} alt="icon" />
              </div>
              <h6>Check your email</h6>
              <p style={{ textAlign: "center" }}>
                We sent a reset password code to your email
              </p>
              <div className="recived-email-content">
                <p>
                  Don’t received the email?{" "}
                  <span
                    //   onClick={handleResendVerification}
                    style={{ cursor: "pointer" }}
                  >
                    <button onClick={handleSubmit}>Click to resend</button>
                  </span>
                </p>
              </div>
            </div>
            <div className="text-center">
              <Link to={"/reset-password"}>Reset Password</Link>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className="container mt-5">
            <div className="forget-password-wrapper">
              <div className="key-icon-wrapper">
                <div className="box">
                  <LuKey />
                </div>
                <h6>Forgot Password</h6>
                <div className="form-wrapper">
                  <form onSubmit={handleSubmit} className="form">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter your email address"
                        required
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      <img src={emailIcon} alt="icon" />
                    </div>
                    <button
                      disabled={loading}
                      type="submit"
                      className="btn-style full"
                    >
                      {loading ? (
                        <Spinner animation="border" size="sm" />
                      ) : (
                        "Send Email"
                      )}
                    </button>
                  </form>
                </div>
                <div className="form-content-wrapper">
                  <p>
                    By inserting your email you confirm you agree to Complya
                    contacting you about our product and services. You can opt
                    out at any time by clicking unsubscribe in our Reports. Find
                    out more about how we use data in our <span><Link href="#">privacy policy.</Link></span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <SignUpFooter />
        </>
      )}
    </div>
  );
}

export default ForgetPassword;
