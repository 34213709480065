import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import { handleModel } from "../../redux/layoutSlices/modelSlice";
import * as yup from "yup";
import { Formik } from "formik";
import {
  createClintAsyncThunk,
  editClintAsyncThunk,
  getClientsAsyncThunk,
} from "../../redux/pagesSlices/clintSlice";
import { toast } from "react-toastify";
import Dropdown from "react-bootstrap/Dropdown";
import { getWorkSpaceStaffAsyncThunk } from "../../redux/pagesSlices/projectSlice";

const CreateClintModel = () => {
  const d = useDispatch();

  const state = useSelector(
    (state) => state.model?.modelState?.CreateClintModel
  );
  const modelArgs = useSelector(
    (state) => state.model?.modelArgs?.CreateClintModel
  );
  console.log("🚀 ~ CreateClintModel ~ modelArgs:", modelArgs);
  const workspaceStaff = useSelector((state) => state.project.WorkSpaceStaff);
  console.log("🚀 ~ CreateClintModel ~ workspaceStaff:", workspaceStaff);
  const [loading, setLoading] = useState(false);
  console.log("🚀 ~ CreateClintModel ~ modelArgs:", modelArgs);
  const handleClose = () => {
    d(handleModel({ model: "CreateClintModel", state: false }));
  };

  useEffect(() => {
    if (state) {
      d(getWorkSpaceStaffAsyncThunk({}));
    }
  }, [d, state]);

  const createClientHandler = async (data) => {
    console.log("data", data);
    setLoading(true);

    try {
      if (modelArgs?.editId) {
        // Handle the update case
        delete data.staffIds; // Modify data as needed for update
        await d(
          editClintAsyncThunk({
            data,
            id: modelArgs?.editId,
            callBack: () => {
              toast.success("Edit Classes Successfully!");
              handleClose();
            },
          })
        );
      } else {
        await d(
          createClintAsyncThunk({
            data,
            callBack: () => {
              toast.success("Create Client Successfully!");
              d(getClientsAsyncThunk({ params: { page: 1, limit: 1000 } }));
              handleClose();
            },
          })
        );
      }
    } catch (error) {
      console.error("Error handling client operation:", error);
      toast.error("An error occurred while processing the request.");
    } finally {
      setLoading(false); // End the loading state regardless of success or error
    }
  };

  const ClassSchemaIn = yup.object().shape({
    firstName: yup.string().required("First Name is Required"),
    lastName: yup.string().required("Last Name is Required"),
    address: yup.string().required("address is Required"),
    dateOfBirth: yup.string().required("Date of Birth is Required"),
    medicalId: yup.string(),
    staffIds: yup.array().min(1, "At least one staff member is required"),
  });

  const [initialValues, setInitialValues] = useState({
    firstName: "",
    lastName: "",
    address: "",
    dateOfBirth: "",
    medicalId: "",
    staffIds: [],
  });

  useEffect(() => {
    if (modelArgs?.editClientData) {
      setInitialValues({
        firstName: modelArgs?.editClientData?.firstName,
        lastName: modelArgs?.editClientData?.lastName,
        address: modelArgs?.editClientData?.address,
        dateOfBirth: modelArgs?.editClientData?.dateOfBirth,
        medicalId: modelArgs?.editClientData?.medicalId,
        staffIds: ["d"],
      });
    }
  }, [modelArgs?.editClientData]);
  const toggleArrayItem = (array, item) =>
    array?.includes(item) ? array.filter((i) => i !== item) : [...array, item];

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={ClassSchemaIn}
      enableReinitialize
      onSubmit={createClientHandler}
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        setFieldValue,
        values,
        errors,
        touched,
      }) => {
        console.log("values", values);
        return (
          <>
            <Modal show={state} onHide={handleClose} centered className="client-model">
              <Modal.Header closeButton>
                <Modal.Title>New Client</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="client-form-body">
                  <h6>Client Name</h6>
                <form onSubmit={handleSubmit} className="form">
                  <div className="row">
                    <div className="col-lg-6">
                    <div className="form-group">
                    <label htmlFor="firstName" className="form-label">
                      First Name
                    </label>
                    <input
                      type="text"
                      id="firstName"
                      name="firstName"
                      className="form-control"
                      placeholder="Enter First Name"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.firstName}
                    />
                    {errors.firstName && touched.firstName && (
                      <div className="text-danger">{errors.firstName}</div>
                    )}
                  </div>
                    </div>
                  
                  <div className="col-lg-6">
                  <div className="form-group">
                    <label htmlFor="lastName" className="form-label">
                      Last Name
                    </label>
                    <input
                      type="text"
                      id="lastName"
                      name="lastName"
                      className="form-control"
                      placeholder="Enter Last Name"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.lastName}
                    />
                    {errors.lastName && touched.lastName && (
                      <div className="text-danger">{errors.lastName}</div>
                    )}
                  </div>
                  </div>
                  <div className="col-lg-6">
                  <div className="form-group">
                    <label htmlFor="address" className="form-label">
                      Address
                    </label>
                    <input
                      type="text"
                      id="address"
                      name="address"
                      className="form-control"
                      placeholder="Enter address"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.address}
                    />
                    {errors.address && touched.address && (
                      <div className="text-danger">{errors.address}</div>
                    )}
                  </div>
                  </div>
                  <div className="col-lg-6">
                  <div className="form-group">
                    <label htmlFor="dateOfBirth" className="form-label">
                      Date of Birth
                    </label>
                    <input
                      type="date"
                      id="dateOfBirth"
                      name="dateOfBirth"
                      className="form-control"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.dateOfBirth}
                    />
                    {errors.dateOfBirth && touched.dateOfBirth && (
                      <div className="text-danger">{errors.dateOfBirth}</div>
                    )}
                  </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                    <label htmlFor="dateOfBirth" className="form-label">
                    Assigned Staff
                    </label>
                    {!modelArgs?.editId && (
                    <>
                      <Dropdown className="custom-dropdown">
                        <Dropdown.Toggle variant="primary" id="dropdown-basic">
                          {values?.staffIds?.length
                            ? workspaceStaff.results
                                .filter((staff) =>
                                  values?.staffIds?.includes(staff.userId)
                                )
                                .map(
                                  (staff) => staff.displayName || staff.email
                                )
                                .join(", ")
                            : "Select Staff"}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          {workspaceStaff?.results.map((staff) => (
                            <Dropdown.Item
                              key={staff.userId}
                              onClick={() =>
                                setFieldValue(
                                  "staffIds",
                                  toggleArrayItem(
                                    values?.staffIds || [],
                                    staff.userId
                                  )
                                )
                              }
                              className={`staff-item ${
                                values?.staffIds?.includes(staff.userId)
                                  ? "selected"
                                  : ""
                              }`}
                            >
                              <img
                                src={staff.picture}
                                alt={staff.firstName}
                                className=""
                              />
                              {staff.displayName || staff.email}
                            </Dropdown.Item>
                          ))}
                        </Dropdown.Menu>
                      </Dropdown>
                      {errors.staffIds && touched.staffIds && (
                        <div className="text-danger">{errors.staffIds}</div>
                      )}
                    </>
                  )}
                    </div>
                  </div>
                  
                  <div className="col-lg-6">
                  <div className="form-group">
                    <label htmlFor="medicalId" className="form-label">
                      Medical ID
                    </label>
                    <input
                      type="text"
                      id="medicalId"
                      name="medicalId"
                      className="form-control"
                      placeholder="Enter Medical ID"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.medicalId}
                    />
                    {errors.medicalId && touched.medicalId && (
                      <div className="text-danger">{errors.medicalId}</div>
                    )}
                  </div>
                  </div>
                  </div>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                      Close
                    </Button>
                    <Button variant="primary btn-purple" type="submit">
                      {loading ? "Saving...." : "Save"}
                    </Button>
                  </Modal.Footer>
                </form>
                </div>
              </Modal.Body>
            </Modal>
          </>
        );
      }}
    </Formik>
  );
};

export default CreateClintModel;
