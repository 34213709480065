import React, { useState } from 'react'
import google from "../assets/images/icons/google.png";
import SignUpFooter from '../components/SignUpFooter';
import LogoBar from '../components/LogoTopBar';
import { Link, useNavigate } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useSignIn, useSignUp } from "@clerk/clerk-react"; // Clerk hook
import { useAuth } from "@clerk/clerk-react";
import { Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import emailIcon from "../assets/images/icons/email-icon.svg"
import passwordIcon from "../assets/images/icons/password-icon.svg"


export default function SignIn() {
    const [loading, setLoading] = useState(false);
    const { signIn, isLoaded } = useSignIn();
    const { isSignedIn } = useAuth();
    const navigate = useNavigate();
    const { signUp, setActive } = useSignUp();

    const SignUpSchema = Yup.object().shape({
        email: Yup.string().email('Invalid email address').required('Email is required'),
        password: Yup.string()
            .required('Password is required'),
    });

    const loginHandler = async (values) => {
        if (!isLoaded) return;
        setLoading(true);
        try {
            const result = await signIn.create({
                identifier: values.email,
                password: values.password,
            });

            console.log("Login successful", result);
            toast.success("Sign-in successful!",);
            if (result.status === 'complete') {
                await setActive({ session: result.createdSessionId })
                navigate("/");
            } else {
                // If the status is not complete, check why. User may need to
                // complete further steps.
                console.error(JSON.stringify(result, null, 2))
            }
            setLoading(false);
            // await signIn.reload();
        } catch (err) {
            console.error("Login failed", err.errors);
            setLoading(false);
            toast.error(err.errors?.[0]?.message);
        }
    };

    const handleContinueWithGoogle = async () => {
        handleSignIn('oauth_google')
    };
    async function handleSignIn(strategy) {
        if (!signIn || !signUp) return null

        const userExistsButNeedsToSignIn =
            signUp.verifications.externalAccount.status === 'transferable' &&
            signUp.verifications.externalAccount.error?.code === 'external_account_exists'

        if (userExistsButNeedsToSignIn) {
            const res = await signIn.create({ transfer: true })

            if (res.status === 'complete') {
                setActive({
                    session: res.createdSessionId,
                })
            }
        }

        const userNeedsToBeCreated = signIn.firstFactorVerification.status === 'transferable'

        if (userNeedsToBeCreated) {
            const res = await signUp.create({
                transfer: true,
            })

            if (res.status === 'complete') {
                setActive({
                    session: res.createdSessionId,
                })
            }
        } else {
            signInWith(strategy)
        }
    }

    const signInWith = (strategy) => {
        return signIn.authenticateWithRedirect({
            strategy,
            redirectUrl: '/oauth-callback',
            redirectUrlComplete: '/',
        })
    }

    return (
        <div className='home-page'>
            <LogoBar />
            <div className="signup-inner-page">
                <div className="container mt-5">
                    <div className='signup-wrapper-form'>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="signup-form-outer">
                                    <div className='google-wrapper-main'>
                                        <div className="google-wrapper" style={{cursor:"pointer"}} onClick={handleContinueWithGoogle}>
                                            <img src={google} alt="google" />
                                            <strong>Sign in with Google</strong>
                                        </div>
                                    </div>

                                    <div className='form-wrapper'>
                                        <Formik
                                            initialValues={{
                                                email: '',
                                                password: '',
                                            }}
                                            validationSchema={SignUpSchema}
                                            onSubmit={(values) => {
                                                loginHandler(values);
                                            }}
                                        >
                                            {({ errors, touched }) => (
                                                <Form action="#" className="form authentication-form">
                                                   <div className='mb-2'>
                                                   <div className="form-group">
                                                        <Field
                                                            type="text"
                                                            name="email"
                                                            className={`form-control ${errors.email && touched.email ? 'is-invalid' : ''}`}
                                                            placeholder='Enter your email address'
                                                        />
                                                        <img src={emailIcon} alt="icon" />
                                                        
                                                    </div>
                                                    <ErrorMessage component="span" name="email" className="validation-message" />
                                                   </div>
                                                   <div className='mb-2'>
                                                   <div className="form-group">
                                                        <Field
                                                            type="password"
                                                            name="password"
                                                            className={`form-control ${errors.password && touched.password ? 'is-invalid' : ''}`}
                                                            placeholder='Enter Password'
                                                        />
                                                        <img src={passwordIcon} alt="icon" />
                                                    </div>
                                                    <ErrorMessage component="span" name="password" className="validation-message" />
                                                   </div>
                                                    <div className="form-opetion">
                                                        <div className="remember-me">
                                                            <input className='check-div' type="checkbox" id="rememberMe" />
                                                            <label htmlFor="rememberMe"> Remember me</label>
                                                        </div>
                                                        <Link to="/forget-password">Forgot password</Link>
                                                    </div>
                                                    <button type="submit" className='sign-in full'>{loading ? <Spinner animation="border" size="sm" /> : "Sign in"}</button>
                                                    <div className='content-wrapper'>
                                                        <p>Don’t have an account? <Link to={'/signup'}>Sign up</Link></p>
                                                    </div>
                                                </Form>
                                            )}
                                        </Formik>

                                        <div className='form-content-wrapper'>
                                            <p>By inserting your email you confirm you agree to Complya contacting you about our
                                                product and services. You can opt out at any time by clicking unsubscribe in our
                                                Reports. Find out more about how we use data in our <span><Link href="#">privacy policy.</Link></span>
                                                </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className='welcome-wrapper-main'>
                                    <h6>Welcome to Complya.</h6>
                                    <p>Complya is a radically new type of CRM. Built on an entirely new
                                        type of data architecture, you'll have proDocs and records of
                                        every interaction within your network in minutes, always
                                        updated in real-time.
                                    </p>
                                    <p className='normal-weight-text'>You'll be able to customize and create your CRM <i>exactly</i> as
                                        you want it.</p>
                                    <p className='normal-weight-text'>Let's begin.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <SignUpFooter />
        </div>
    )
}
