import React from 'react'
import ClintTopBar from '../components/ClintTopBar'
import { Link } from 'react-router-dom'
import { GrAttachment } from "react-icons/gr";
import { BsThreeDots } from "react-icons/bs";
import Dropdown from "react-bootstrap/Dropdown";
import { FiEdit } from "react-icons/fi";
import { MdDelete } from "react-icons/md";
import avtar from '../assets/images/icons/avatar-icon.svg';
import check from '../assets/images/icons/check.svg';
import close from '../assets/images/icons/auto-closed.svg';

const ClientSession = () => {
  return (
    <>
    <ClintTopBar />
    <div className='session-table'>
    <div className='client-table-wrapper'>
        <div class="container-fluid">
          <div class="task-table-container" style={{ minHeight: "250px" }}>
            <table class="task-table">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Assigned Staff</th>
                  <th>Session Start</th>
                  <th>Session End</th>
                  <th>Status</th>
                  <th>Notes Preview</th>
                  <th>Attachment</th>
                  <th></th>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <td>
                    <div className='icon-wrapper'>
                      <Link>11/27/2024</Link>
                    </div>
                  </td>
                  <td className="assignee">
                    <Dropdown
                      className="dropdown-wraper project-assign-dropdown">
                      <Dropdown.Toggle>
                        <img src={avtar} alt='Avtar' />
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                      <Dropdown.Item
                                >
                                  <div className="status-icon">
                                    <img
                                      src={avtar}
                                      alt="Assignee"
                                    />
                                    <p>Haseeb Ramzan</p>
                                  </div>
                                </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                  <td>
                    <div className='icon-wrapper'>
                        <p>10:00 AM</p>
                    </div>
                  </td>
                  <td>11:30 AM</td>
                  <td>
                    <div className='status-bg'>
                        <img src={check} alt='Checked icon' />
                        <p>completed</p>
                    </div>
                  </td>
                  <td>Focused on speech
                    <br></br>
                     skills.</td>
                  <td>
                    <div className="icon-wrapper">
                      <GrAttachment />
                      <p>3</p>
                    </div>
                  </td>
                  <td>
                    <Dropdown className="dropdown-wraper">
                      <Dropdown.Toggle
                        style={{
                          background: "transparent",
                          border: "none",
                          color: "black",
                        }}
                      >
                        <BsThreeDots />
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item>
                          <FiEdit />
                          <span>Edit</span>
                        </Dropdown.Item>
                        <Dropdown.Item className="delete">
                          <MdDelete />
                          <span className="delete">Delete</span>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>


                </tr>

                <tr>
                  <td>
                    <div className='icon-wrapper'>
                      <Link>11/27/2024</Link>
                    </div>
                  </td>
                  <td className="assignee">
                    <Dropdown
                      className="dropdown-wraper project-assign-dropdown">
                      <Dropdown.Toggle>
                        <img src={avtar} alt='Avtar' />
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                      <Dropdown.Item
                                >
                                  <div className="status-icon">
                                    <img
                                      src={avtar}
                                      alt="Assignee"
                                    />
                                    <p>Haseeb Ramzan</p>
                                  </div>
                                </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                  <td>
                    <div className='icon-wrapper'>
                        <p>10:00 AM</p>
                    </div>
                  </td>
                  <td>11:30 AM</td>
                  <td>
                    <div className='status-bg'>
                        <img src={close} alt='Checked icon' />
                        <p>completed</p>
                    </div>
                  </td>
                  <td>Focused on speech
                    <br></br>
                     skills.</td>
                  <td>
                    <div className="icon-wrapper">
                      <GrAttachment />
                      <p>3</p>
                    </div>
                  </td>
                  <td>
                    <Dropdown className="dropdown-wraper">
                      <Dropdown.Toggle
                        style={{
                          background: "transparent",
                          border: "none",
                          color: "black",
                        }}
                      >
                        <BsThreeDots />
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item>
                          <FiEdit />
                          <span>Edit</span>
                        </Dropdown.Item>
                        <Dropdown.Item className="delete">
                          <MdDelete />
                          <span className="delete">Delete</span>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>


                </tr>


              </tbody>
            </table>
          </div>
        </div>

      </div>
    </div>
      
    </>
  )
}

export default ClientSession
