/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteDocumentsAsyncThunk,
  getDocumentsAsyncThunk,
} from "../redux/pagesSlices/docSlice";
import { useParams } from "react-router-dom";
import threedot from "../assets/images/icons/threedot.svg";
import Dropdown from "react-bootstrap/Dropdown";
import docx from "../assets/images/icons/docx.svg";
import del from "../assets/images/icons/del.svg";
import Loadingdata from "../pages/loadingdata";
import NodataFound from "./NodataFound";
import { handleModel } from "../redux/layoutSlices/modelSlice";
import { getProjectAsyncThunk } from "../redux/pagesSlices/projectSlice";

function ProjectDocuments() {
  const d = useDispatch();
  const id = useParams().id;
  const document = useSelector((state) => state?.doc?.documents);
  const loading = useSelector(
    (state) => state?.doc?.loadings?.getDocumentsAsyncThunk
  );

  const getData = () => {
    d(getDocumentsAsyncThunk({ params: { page: 1, limit: 1000 }, id: id }));
  };

  useEffect(() => {
    getData();
  }, [d]);

  const deleteProject = (docId) => {
    d(
      deleteDocumentsAsyncThunk({
        id: docId,
        callBack: () => {
          getData();
          d(getProjectAsyncThunk({ id }));
        },
      })
    );
  };

  const formatDate = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    const options = { year: "numeric", month: "long", day: "numeric" };
    return date.toLocaleDateString("en-US", options);
  };

  return (
    <div>
      <div className="docs-table-list">
        <div className="task-table-container">
          <table className="task-table">
            {loading ? (
              <Loadingdata />
            ) : document?.results?.length === 0 ? (
              <NodataFound title={"File"} />
            ) : (
              <>
                <thead>
                  <tr>
                    <th>File Name</th>
                    <th>Date Uploaded</th>
                  </tr>
                </thead>
                <tbody>
                  {document?.results?.map((item, idx) => (
                    <tr key={idx}>
                      <td>
                        <div className="icon-wrapper">
                          <img src={docx} alt="document" />
                          <p>{item?.originalName}</p>
                        </div>
                      </td>
                      <td>
                        <div className="icon-wrapper">
                          <span>{formatDate(item?.updatedAt)}</span>
                          <Dropdown>
                            <Dropdown.Toggle
                              style={{
                                background: "transparent",
                                border: "none",
                                color: "black",
                              }}
                              id="dropdown-basic"
                              className="custom-dropdown-toggle"
                            >
                              <img src={threedot} alt="threedot" />
                            </Dropdown.Toggle>

                            <Dropdown.Menu className="delete-button">
                              <Dropdown.Item
                                href="#/action-1"
                                onClick={() => deleteProject(item?.id)}
                              >
                                <img src={del} alt="delete" />
                                <span>Delete</span>
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </>
            )}
          </table>
        </div>
      </div>
    </div>
  );
}

export default ProjectDocuments;
