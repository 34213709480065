import React, { useState, useEffect } from "react";
import profile from "../assets/images/profile.png";
import { HiOutlineUpload } from "react-icons/hi";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";

function General() {
  const selectedWorkSpace = useSelector((state) => state.workspace.workspace);
  const [logo, setLogo] = useState(selectedWorkSpace?.imageUrl);
  const [workspaceName, setWorkspaceName] = useState(selectedWorkSpace?.name);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setWorkspaceName(selectedWorkSpace?.name || "");
  }, [selectedWorkSpace?.name]);

  const updateWorkspace = async () => {
    setLoading(true);
    try {
      if (logo instanceof File) {
        await selectedWorkSpace.setLogo({ file: logo });
      }
      if (workspaceName && workspaceName !== selectedWorkSpace?.name) {
        await selectedWorkSpace.update({ name: workspaceName });
      }
      setLoading(false);
      toast.success("Workspace updated successfully!");
    } catch (error) {
      console.log("🚀 ~ updateWorkspace ~ error:", error);
      toast.error(
        error?.message || "An unexpected error occurred. Please try again."
      );
    }
  };
  return (
    <div className="account-setting">
      <div className="account-setting-wrapper">
        <div className="tabs">
          <ul>
            <li>Workspace settings</li>
            <li>
              <span className="slash-style">/</span>
            </li>
            <li className="active">General</li>
          </ul>
        </div>
        <h6 className="mt-4">General</h6>
        <p>Change the settings for your current workspace here</p>
        <div className="bootom"></div>

        <div className="row">
          <div className="col-md-12">
            <div className="prfile-form">
              <form action="" className="form">
                <div class="mb-3">
                  <label for="exampleInputEmail1" class="form-label">
                    Workspace name
                  </label>
                  <input
                    type="text"
                    className="form-control w-100"
                    id="exampleInputEmail1"
                    value={workspaceName}
                    placeholder="Enter workspace name"
                    onChange={(e) => setWorkspaceName(e.target.value)}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>

        <div className="account-profile-wrapper">
          <div className="account-profile">
            <img
              src={
                logo instanceof File
                  ? URL.createObjectURL(logo)
                  : selectedWorkSpace?.imageUrl
                  ? selectedWorkSpace?.imageUrl
                  : profile
              }
              alt=""
            />
            <div className="accounts-wrapper">
              <h6 className="py-0">Profile Picture</h6>
              <div className="account-btn">
                <label htmlFor="upload" className="btn-style">
                  <HiOutlineUpload /> Upload image
                  <input
                    id="upload"
                    type="file"
                    onChange={(e) => {
                      setLogo(e.target.files[0]);
                    }}
                    accept="image/*"
                    style={{ display: "none" }}
                  />
                </label>
                {(logo instanceof File ||
                  workspaceName !== selectedWorkSpace?.name) && (
                  <button
                    className="remove"
                    onClick={updateWorkspace}
                    disabled={loading}
                  >
                    {loading ? <Spinner size="sm" /> : "Save"}
                  </button>
                )}
              </div>
              <p>We support PNGs, JPEGs and GIFs under 10MB</p>
            </div>
          </div>
          <div className="bootom"></div>
        </div>
        <div className="bootom"></div>
        {/* <div className="del mt-5">
          <a href="" className="del-btn">
            Delete workspace
          </a> */}
        {/* </div> */}
      </div>
    </div>
  );
}

export default General;
