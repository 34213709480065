import React, { useEffect } from "react";
import ClintTopBar from "../components/ClintTopBar";
import { Link } from "react-router-dom";
import { GrAttachment } from "react-icons/gr";
import mobile from "../assets/images/icons/carbon_mobile-session.svg";
import { BsThreeDots } from "react-icons/bs";
import Dropdown from "react-bootstrap/Dropdown";
import { FiEdit } from "react-icons/fi";
import { MdDelete } from "react-icons/md";
import avtar from "../assets/images/icons/avatar-icon.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteClintAsyncThunk,
  getClientsAsyncThunk,
} from "../redux/pagesSlices/clintSlice";
import { handleModel } from "../redux/layoutSlices/modelSlice";
import NodataFound from "../components/NodataFound";

const Clints = () => {
  const d = useDispatch();
  const Clients = useSelector((state) => state.client.client);
  console.log("🚀 ~ Clints ~ Clients:", Clients);
  const getData = () => {
    d(
      getClientsAsyncThunk({
        params: { page: 1, limit: 1000 },
      })
    );
  };

  useEffect(() => {
    getData();
  }, []);

  const deleteClient = (id) => {
    d(
      deleteClintAsyncThunk({
        id,
        callBack: () => {
          getData();
        },
      })
    );
  };

  const editClient = (item) => {
    d(
      handleModel({
        model: "CreateClintModel",
        state: true,
        args: {
          editId: item?.id,
          editClientData: item,
        },
      })
    );
  };

  return (
    <div className="projects-page h-100">
      <ClintTopBar />
      <div className="client-table-wrapper">
        <div class="container-fluid h-100">
          <div class="task-table-container" style={{ minHeight: "250px" }}>
          {Clients?.results?.length === 0 ? (
                <NodataFound title={"Clients"} />
              ) : (
            <table class="task-table">
              <thead>
                <tr>
                  <th>First Name</th>
                  <th>Last Name</th>
                  <th>Address</th>
                  <th>DOB</th>
                  <th>Medical ID</th>
                  <th>Assigned Staff</th>
                  <th>Files Count</th>
                  <th>Active Sessions</th>
                  <th></th>
                </tr>
              </thead>
                <>
              
                  <tbody>
                    {Clients?.results?.map((client) => (
                      <tr key={client.id}>
                        <td>
                          <div className="icon-wrapper">
                            <Link>{client.firstName}</Link>
                          </div>
                        </td>
                        <td>
                          <div className="icon-wrapper">
                            <p>{client.lastName}</p>
                          </div>
                        </td>
                        <td>{client.address}</td>
                        <td>
                          {new Date(client.dateOfBirth).toLocaleDateString()}
                        </td>
                        <td>{client.medicalId}</td>
                        <td className="assignee">
                          <Dropdown className="dropdown-wraper project-assign-dropdown">
                            <Dropdown.Toggle>
                              <img
                                src={
                                  client.ClientStaff[0]?.user?.picture || avtar
                                }
                                alt="Avatar"
                              />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              {client.ClientStaff.map((staff) => (
                                <Dropdown.Item key={staff.user.userId}>
                                  <div className="status-icon">
                                    <img
                                      src={staff.user.picture}
                                      alt={
                                        staff.user.displayName ||
                                        staff.user.email
                                      }
                                    />
                                    <p>
                                      {staff.user.displayName ||
                                        staff.user.email}
                                    </p>
                                  </div>
                                </Dropdown.Item>
                              ))}
                            </Dropdown.Menu>
                          </Dropdown>
                        </td>
                        <td>
                          <div className="icon-wrapper">
                            <GrAttachment />
                            <p>3</p>
                          </div>
                        </td>
                        <td>
                          <div className="icon-wrapper">
                            <img src={mobile} alt="mobile icon" />
                            <p>5</p>
                          </div>
                        </td>
                        <td>
                          <Dropdown className="dropdown-wraper">
                            <Dropdown.Toggle
                              style={{
                                background: "transparent",
                                border: "none",
                                color: "black",
                              }}
                            >
                              <BsThreeDots />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item onClick={() => editClient(client)}>
                                <FiEdit />
                                <span>Edit</span>
                              </Dropdown.Item>
                              <Dropdown.Item
                                className="delete"
                                onClick={() => deleteClient(client?.id)}
                              >
                                <MdDelete />
                                <span className="delete">Delete</span>
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </td>
                      </tr>
                    ))}
                  </tbody>
              
                </>
            </table>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Clints;
