import React from "react";
import work from "../../src/assets/images/work.png";
import { HiOutlineUpload } from "react-icons/hi";
import profile from "../../src/assets/images/profile.png";
import * as Yup from "yup";
import { ErrorMessage, Formik } from "formik";
import { createWorkspaceAsyncThunk } from "../redux/pagesSlices/workspaceSlice";
import { useDispatch } from "react-redux";
import { ApiRequests } from "../service/ApiRequests";
import { useUser } from "@clerk/clerk-react";
import complyaLogo from "../assets/images/icons/logo-square.png";
import { MdKeyboardArrowDown } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { GoArrowLeft } from "react-icons/go";

export default function CreateYourWorkSpaceStep() {
  const d = useDispatch();
  const { user } = useUser();

  const validationSchema = Yup.object().shape({
    companyLogo: Yup.mixed()
      .required("Company Logo is required")
      .test("fileSize", "File too large", (value) => {
        if (value && value.size > 10 * 1024 * 1024) {
          return false; // Max size 10MB
        }
        return true;
      }),
    name: Yup.string().required("Company name is required"),
  });

  const initialValues = {
    companyLogo: null,
    name: "",
  };

  const navigate = useNavigate();
  const handleSubmit = async (values) => {
    try {
      const formData = new FormData();
      formData.append("companyLogo", values.companyLogo);
      formData.append("name", values.name);
      await d(
        createWorkspaceAsyncThunk({ data: formData, callBack: () => {} })
      );
      // await ApiRequests.updateMetadata({ step: 2 });
      await user.reload();
      navigate("/invitation");
    } catch (error) {
      console.log("🚀 ~ handleUpdateUser ~ error:", error);
    }
  };

  return (
    <div className="personal-information-step">
      <div className="container mt-5">
        <div className="signup-wrapper-form">
          <GoArrowLeft
            size={"25px"}
            onClick={() => navigate("/")}
            style={{
              marginTop: "-90px",
              marginLeft: "-80px",
              cursor: "pointer",
            }}
          />

          <div className="row">
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({
                values,
                isSubmitting,
                handleSubmit,
                handleChange,
                handleBlur,
                setFieldValue,
              }) => (
                <>
                  {/* Left Side - Form */}
                  <div className="col-md-6">
                    <div className="signup-form-outer">
                      <div className="personal-information">
                        <div className="info-wrapper-main">
                          <div className="info-wrapper-content">
                            <p>2/4</p>
                            <h6>Create your workspace</h6>
                            <div className="profile-picture-wrapper">
                              <div className="profile-img">
                                <img
                                  src={
                                    values.companyLogo
                                      ? URL.createObjectURL(values.companyLogo)
                                      : profile
                                  }
                                  alt=""
                                />
                              </div>
                              <div className="peofile-content">
                                <h6>Company logo</h6>
                                <div className="profile-btn-wrapper">
                                  <div className="profile-btn">
                                    <label htmlFor="upload">
                                      <div className="replace">
                                        <HiOutlineUpload />
                                        <p>Replace image</p>
                                      </div>
                                      <input
                                        type="file"
                                        onChange={(e) =>
                                          setFieldValue(
                                            "companyLogo",
                                            e.target.files[0]
                                          )
                                        }
                                        accept="image/*"
                                        id="upload"
                                        style={{ display: "none" }}
                                      />
                                    </label>
                                  </div>
                                </div>
                                <ErrorMessage
                                  component="div"
                                  name="companyLogo"
                                  className="text-danger"
                                />
                                <p className="mt-3">
                                  *.png, *.jpeg files up to 10MB, at least 400px
                                  by 400px
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="form-wrapper">
                        <div className="form">
                          <div className="mb-3">
                            <label htmlFor="name" className="form-label">
                              Company name
                            </label>
                            <input
                              type="text"
                              className="form-control ps-3"
                              id="name"
                              value={values.name}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              name="name"
                              placeholder="Enter your company name"
                            />
                            <ErrorMessage
                              component="div"
                              name="name"
                              className="text-danger"
                            />
                          </div>
                          <button
                            type="submit"
                            onClick={handleSubmit}
                            className="btn-style full"
                          >
                            {isSubmitting ? "Submitting..." : "Continue"}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Right Side - Sidebar Content */}
                  <div className="col-md-6">
                    <div className="welcome-wrapper-main">
                      <div className="profile-card-img position-relative">
                        <div className="img-box">
                          <div className="sidebar-header">
                            <div className="sidebar-logo">
                              <div className="logo">
                                <img
                                  src={
                                    values.companyLogo
                                      ? URL.createObjectURL(values.companyLogo) // Display the uploaded logo if available
                                      : complyaLogo // Default logo if no image is uploaded
                                  }
                                  alt="logo"
                                />{" "}
                              </div>
                              <div className="username-text">
                                {values.name || "Complya"}
                                <MdKeyboardArrowDown />
                              </div>
                            </div>
                            <div>
                              <MdKeyboardArrowDown />
                            </div>
                          </div>
                          <div className="input_box">
                            <input type="text" className="form-control" />
                          </div>
                        </div>
                        <img src={work} alt="profilecard" />
                      </div>
                    </div>
                  </div>
                </>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
}
