import { useAuth, useClerk, useSession, useSignIn } from "@clerk/clerk-react";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { setLocalValue } from "../helpers/localStorageHelpers";
import Loadingdata from "./loadingdata";

const WorkSpaceInvite = () => {
  const location = useLocation();
  const { isSignedIn, session  } = useSession();
  const { signOut } = useClerk();
  const { getToken, userId } = useAuth();
  const { signIn, setActive: setActiveSignIn } = useSignIn();
  const navigate = useNavigate();
  const { workspaceName, invitedBy } = useParams();
  const [acceptLoading, setAcceptLoading] = useState(false);

  const getQueryParams = () => {
    const params = new URLSearchParams(location.search);
    return {
      token: params.get("__clerk_ticket"),
      accountStatus: params.get("__clerk_status"),
      email: params.get("email"),
    };
  };

  const handleAcceptInvite = async (token, accountStatus, email) => {
    if (!token) {
      return handleError({ message: "Invalid invitation link." });
    }
    if(isSignedIn){
      // logout user
      await signOut({
        sessionId: session.id,
      });
    } 
    setAcceptLoading(true);
    try {
      await acceptInvitation(token, accountStatus, email);
    } catch (error) {
      handleError(error);
    } finally {
      setAcceptLoading(false);
    }
  };

  const acceptInvitation = async (token, accountStatus, email) => {
    if (accountStatus === "sign_in") {
      const signInAttempt = await signIn.create({
        strategy: "ticket",
        ticket: token,
      });
      if (signInAttempt.status === "complete") {
        await setActiveSignIn({
          session: signInAttempt.createdSessionId,
        });
        const authToken = await getToken();
        setLocalValue("access-token", authToken);
        setLocalValue("user-id", userId);
        navigate(`/`);
      }
    }
  };

  const handleError = (error) => {
    if (error.message) {
      toast.error(error.message);
    } else {
      toast.error("An unexpected error occurred. Please try again.");
    }
  };

  useEffect(() => {
    const { token, accountStatus, email } = getQueryParams();
    if (accountStatus === "sign_up") {
      localStorage.setItem("__clerk_ticket", token);
      localStorage.setItem("__clerk_status", "sign_in");
      localStorage.setItem("invite-workspaceName", workspaceName);
      localStorage.setItem("invite-invitedBy", invitedBy);
      localStorage.setItem("invite-email", email);
      navigate("/signup?email=" + email);
    } else if (accountStatus === "sign_in") {
      if(signIn){
        handleAcceptInvite(token, accountStatus, email);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signIn]);

  return <>{acceptLoading ? <Loadingdata /> : null}</>;
};

export default WorkSpaceInvite;
