import React from "react";
import PersoanlCard from "../components/PersoanlCard";
import profilecard from "../assets/images/profilecard.png";
import { useUser } from "@clerk/clerk-react";
import * as Yup from "yup";
import { Formik } from "formik";
import LogoBar from "../components/LogoTopBar";

function PersonalInfo() {
  const { user } = useUser();
  const userSchema = Yup.object().shape({
    firstName: Yup.string().required("First name is required"),
    lastName: Yup.string().required("Last name is required"),
  });
  return (
    <div className="home-page">
      <LogoBar />
      <div className="container mt-5">
        <div className="signup-wrapper-form">
          <div className="row">
            <div className="col-md-6 mb-3">
              <Formik
                initialValues={{
                  firstName: user?.firstName || "",
                  lastName: user?.lastName || "",
                }}
                enableReinitialize
                validationSchema={userSchema}
                onSubmit={(values) => {
                  // createAccount(values);
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleSubmit,
                  handleChange,
                  handleBlur,
                }) => (
                  <div className="signup-form-outer">
                    <PersoanlCard />
                    <div className="form-wrapper">
                      <div className="form">
                        <div class="mb-3">
                          <label for="firstName" class="form-label">
                            First name
                          </label>
                          <input
                            type="text"
                            value={values?.firstName}
                            class="form-control"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            id="firstName"
                            name="firstName"
                            aria-describedby="emailHelp"
                          />
                          {errors.firstName && touched.firstName ? (
                            <div className="error">{errors.firstName}</div>
                          ) : null}
                        </div>
                        <div class="mb-3 w-75">
                          <label for="lastName" class="form-label">
                            Last name
                          </label>
                          <input
                            value={values?.lastName}
                            type="text"
                            class="form-control"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            id="lastName"
                            aria-describedby="emailHelp"
                            name="lastName"
                          />
                          {errors.lastName && touched.lastName ? (
                            <div className="error">{errors.lastName}</div>
                          ) : null}
                        </div>
                        <div class="mb-3">
                          <label for="exampleInputEmail1" class="form-label">
                            Email
                          </label>
                          <input
                            value={user?.primaryEmailAddress}
                            disabled
                            type="text"
                            onBlur={handleBlur}
                            className="form-control"
                            placeholder="Enter your email address"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="toggle-content-wrapper">
                      <div className="toggle-content">
                        <h6>Subscribe to product update Reports</h6>
                        <p>
                          Get the latest updates about features and product
                          updates.
                        </p>
                      </div>
                      <label class="switch">
                        <input type="checkbox" />
                        <span class="slider round"></span>
                      </label>
                    </div>
                    <button
                      type="submit"
                      onClick={handleSubmit}
                      className="btn-style full"
                    >
                      Continue
                    </button>
                  </div>
                )}
              </Formik>
            </div>
            <div className="col-md-6">
              <div className="welcome-wrapper-main">
                <div className="profile-card-img">
                  <img src={profilecard} alt="profilecard" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PersonalInfo;
