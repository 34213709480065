import React from "react";
import project from "../assets/images/icons/ph_cube-bold.svg";
import add from "../assets/images/icons/add.svg";
import checklistIcon from "../assets/images/icons/checklist-icon.svg";
import overview from "../assets/images/icons/overview-icon.svg";
import { FaChevronRight } from "react-icons/fa";
import { Link, useParams } from "react-router-dom";
import { handleModel } from "../redux/layoutSlices/modelSlice";
import { useDispatch, useSelector } from "react-redux";

export default function Topbar({
  handleCreateProjectShow,
  activeTab,
}) {
  const d = useDispatch();
  const userRole = useSelector((state) => state.workspace.userRole);
  const { id } = useParams();
  const SingleProject = useSelector((state) => state?.user?.project);

  const currentURL = window.location.href;
  return (
    <>
      <div className="main-header">
        <div className="row d-flex align-items-baseline">
          <div className="col-md-6 col-sm-8 col-12 ps-0">
            <div className="header">
              <div className="project-heading">
                <Link to={"/session"}>Sessions</Link>
                {/* <FaChevronRight size={10} color="#7C7C7CB2" />

                <button
                  className="project-wrapper"
                  // onClick={handleProjectClick}
                >
                  <img src={project} alt="Project" />
                  <p>
                    {currentURL.includes("project-detail")
                      ? SingleProject.name
                      : "All Sessions"}
                  </p>
                </button> */}

                {activeTab === "Details" ? (
                  <>
                    <FaChevronRight size={10} color="#7C7C7CB2" />
                    <button
                      className="project-wrapper"
                      // onClick={handleOverviewClick}
                    >
                      <img src={overview} alt="Project" />
                      <p>
                        {activeTab === "Details" ||
                        activeTab === "Clint" ||
                        activeTab === "Notes" ||
                        activeTab === "Tasks" ||
                        activeTab === "Docs"
                          ? "Details"
                          : ""}
                      </p>
                    </button>
                  </>
                ) : (
                  <></>
                )}

                {activeTab === "Notes" ||
                activeTab === "Tasks" ||
                activeTab === "Docs" ? (
                  <>
                    <FaChevronRight size={10} color="#7C7C7CB2" />
                    <button
                      className="project-wrapper"
                      // onClick={handleChecklistClick}
                    >
                      <img src={checklistIcon} alt="Project" />
                      <p>
                        {activeTab === "Tasks"
                          ? "Tasks"
                          : activeTab === "Notes"
                          ? "Notes"
                          : activeTab === "Checklist"
                          ? "Checklist"
                          : activeTab === "Docs"
                          ? "Docs"
                          : ""}
                      </p>
                    </button>
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
          <div className="col-md-6 col-sm-4 col-12">
            <div className="flex-wrapper">
              {userRole?.role === "org:admin" ? (
                <>
                  {activeTab === "Tasks" && (
                    <div
                      className="create-sec"
                      onClick={() => {
                        d(
                          handleModel({
                            model: "CreateTaskModal",
                            state: true,
                            args: {
                              id: id,
                            },
                          })
                        );
                      }}
                    >
                      <button>
                        <img src={add} alt="Add icon" />
                        <span>Create Task</span>
                      </button>
                    </div>
                  )}
                  {!id && (
                    <div
                      className="create-sec"
                      onClick={() => {
                        d(
                          handleModel({
                            model: "CreateProjectModal",
                            state: true,
                            args: {},
                          })
                        );
                      }}
                    >
                      <button>
                        <img src={add} alt="Add icon" />
                        <span>Create Session</span>
                      </button>
                    </div>
                  )}
                </>
              ) : null}

              {activeTab === "Docs" && (
                <div
                  className="create-sec"
                  onClick={() => {
                    d(
                      handleModel({
                        model: "creatDocModel",
                        state: true,
                        args: {
                          id: id,
                        },
                      })
                    );
                  }}
                >
                  <button>
                    <img src={add} alt="Add icon" />
                    <span>Create Files</span>
                  </button>
                </div>
              )}
              {activeTab === "Notes" && (
                <div className="create-sec">
                  <button
                    onClick={() =>
                      d(
                        handleModel({
                          model: "CreateNoteModal",
                          state: true,
                          args: { id: id },
                        })
                      )
                    }
                  >
                    <img src={add} alt="Add icon" />
                    <span>Create Note</span>
                  </button>
                </div>
              )}

              {activeTab === "Clint" && (
                <div className="create-sec">
                  <button
                    onClick={() =>
                      d(
                        handleModel({
                          model: "CreateClintModal",
                          state: true,
                          args: { id: id },
                        })
                      )
                    }
                  >
                    <img src={add} alt="Add icon" />
                    <span>Create Clint</span>
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
