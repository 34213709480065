import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Dropdown from "react-bootstrap/Dropdown";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  createProjectAsyncThunk,
  editProjectAsyncThunk,
  getProjectsAsyncThunk,
  getWorkSpaceAsyncThunk,
} from "../redux/pagesSlices/projectSlice";
import { useDispatch, useSelector } from "react-redux";
import { Spinner } from "react-bootstrap";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import status from "../assets/images/icons/Frame (1).png";
import date from "../assets/images/icons/Frame (4).png";
import todo from "../assets/images/icons/status1.svg";
import progress from "../assets/images/icons/progress.svg";
import done from "../assets/images/icons/statusicon4.svg";
import medium from "../assets/images/icons/svg7.svg";
import urgent from "../assets/images/icons/svg9.svg";
import high from "../assets/images/icons/svg8.svg";
import low from "../assets/images/icons/low.png";
import { RiAttachment2 } from "react-icons/ri";
import deleteicon from "../assets/images/icons/delete.svg";
import profileimg from "../assets/images/profile.png";
import Emojis from "./Emojis";
import { useUser } from "@clerk/clerk-react";
import { createDocumentAsyncThunk } from "../redux/pagesSlices/docSlice";
import due from "../assets/images/icons/x-Vector.svg";
import Priority from "../assets/images/icons/Priority.svg";
import { toast } from "react-toastify";
import { handleModel } from "../redux/layoutSlices/modelSlice";
import { formatStatus } from "../helpers/globle";

const projectSchema = Yup.object().shape({
  name: Yup.string().required("Project name is required").max(100),
  description: Yup.string().required("Project description is required"),
  assignees: Yup.array().min(1, "At least one assignee is required").nullable(),
  status: Yup.string().required("Status is required"),
  icon: Yup.string(),
  priority: Yup.string().required("Priority is required"),
});

function CreateProjectModal() {
  const [showEmojis, setShowEmojis] = useState(false);
  const state = useSelector(
    (state) => state.model.modelState.CreateProjectModal
  );
  const modelArgs = useSelector(
    (state) => state.model.modelArgs.CreateProjectModal
  );

  const editId = modelArgs?.editId;
  const editProjectData = modelArgs?.editProjectData;
  const { user } = useUser();
  const d = useDispatch();
  const workspace = useSelector((state) => state.project.WorkSpace);
  const loading = useSelector(
    (state) => state.project.loadings?.createProjectAsyncThunk
  );
  const [file, setFile] = useState(null);
  const toggleEmojis = () => {
    setShowEmojis(!showEmojis);
  };
  const handleClose = () => {
    d(handleModel({ model: "CreateProjectModal", state: false }));
    setShowEmojis(false);

  };
  useEffect(() => {
    if (state) {
      d(getWorkSpaceAsyncThunk({}));
    }
  }, [d, state]);

  const handelCreatProject = async (values, { resetForm }) => {
    const action = editId ? editProjectAsyncThunk : createProjectAsyncThunk;
    if (editId) {
      delete values.assignees;
      delete values.attachments;
    } else {
      const attachmentsPromise = values.attachments?.map((file) => {
        const data = new FormData();
        data.append("file", file);
        return d(createDocumentAsyncThunk({ data }));
      });

      const attachments = await Promise.all(attachmentsPromise);
      values.attachments = attachments.map(
        (attachment) => attachment.payload.id
      );
    }
    d(
      action({
        id: editId,
        data: values,
        callBack: () => {
          d(getProjectsAsyncThunk({ limit: 1000 }));
          handleClose();
          if (editId) {
            toast.success("Session Updated Successfully");
          }
          resetForm();
        },
      })
    );
  };


  const toggleArrayItem = (array, item) =>
    array?.includes(item) ? array.filter((i) => i !== item) : [...array, item];
  
  const [initialValues, setInitialValues] = useState({
    name: editProjectData?.name || "",
    description: editProjectData?.description || "",
    status: editProjectData?.status || "todo",
    priority: editProjectData?.priority || "low",
    assignees: [], 
    icon:
      editProjectData?.icon || "https://complyacdn.dev/projectIcon/Vector.png",
    dueDate: editProjectData?.dueDate || new Date(),
    attachments: [],
  });
  useEffect(() => {
    if (editProjectData) {
      setInitialValues({
        name: editProjectData?.name || "",
        description: editProjectData?.description || "",
        status: editProjectData?.status || "todo",
        priority: editProjectData?.priority || "low",
        icon:
          editProjectData?.icon ||
          "https://complyacdn.dev/projectIcon/Vector.png",
        dueDate: editProjectData?.dueDate || new Date(),
        attachments: [],
      });
    }
  }, [editProjectData]);

  return (
    <>
      <Modal show={state} onHide={handleClose} className="create-project-modal">
        <Modal.Header closeButton>
          <Modal.Title>{editId ? "Update Project" : "New Project"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={initialValues}
            enableReinitialize
            validationSchema={projectSchema}
            onSubmit={handelCreatProject}
          >
            {({ setFieldValue, values, isSubmitting }) => (
              <Form>
                <div className="prg-name-div">
                  <img
                    className="icon"
                    src={values?.icon}
                    alt="vectore"
                    onClick={toggleEmojis}
                  />
                  <div className="project-name-text">
                    <Field
                      type="text"
                      placeholder="Project Name"
                      name="name"
                      className="form-control fw-bold fs-3 mb-2 shadow-none"
                    />
                    <ErrorMessage
                      name="name"
                      component="div"
                      className="text-danger"
                      style={{ fontSize: "12px", marginTop: "-14px" }}
                    />
                  </div>

                  <div className="form-control">
                    <Field
                      as="textarea"
                      placeholder="Add Short Description"
                      name="description"
                      className="form-control mt-2 text-secondary shadow-none"
                    />
                    <ErrorMessage
                      name="description"
                      component="div"
                      className="text-danger"
                      style={{ fontSize: "12px", marginTop: "-6px" }}
                    />
                  </div>
                  {showEmojis && (
                    <div className="emojis-overlay">
                      <Emojis
                        onEmojiClick={(emoji) => {
                          setFieldValue("icon", emoji);
                          setShowEmojis(false);
                        }}
                      />
                    </div>
                  )}
                </div>

                <div className="dropdown-btn-row align-items-start">
                  {/* Status Dropdown */}
                  <div>
                    <Dropdown
                      onSelect={(status) => setFieldValue("status", status)}
                      className="status-dropdown"
                    >
                      <Dropdown.Toggle id="dropdown-basic">
                        <div className="d-flex gap-1 align-items-center">
                          {values.status === "todo" ? (
                            <img
                              className="status-icon"
                              src={todo}
                              alt="status"
                            />
                          ) : values.status === "in_progress" ? (
                            <img src={progress} alt="progresss" />
                          ) : values.status === "backlog" ? (
                            <img
                              src={status}
                              alt="progresss"
                              className="status-icon"
                            />
                          ) : values.status === "done" ? (
                            <img src={done} alt="done" />
                          ) : (
                            <img
                              className="status-icon"
                              src={status}
                              alt="status"
                            />
                          )}
                          <span className="text-black">
                            {" "}
                            {values.status
                              ? formatStatus(values.status)
                              : "Select Status"}
                          </span>
                        </div>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item eventKey="todo">
                          <div className="d-flex gap-1 align-items-center">
                            <img src={todo} alt="status" />
                            <span
                              style={{ fontSize: "12px", fontWeight: "400" }}
                            >
                              Todo
                            </span>
                          </div>
                        </Dropdown.Item>
                        {/* ///////////////////////// */}
                        <Dropdown.Item eventKey="in_progress">
                          <div className="d-flex gap-1 align-items-center">
                            <img src={progress} alt="progresss" />
                            <span
                              style={{ fontSize: "12px", fontWeight: "400" }}
                            >
                              In Progress
                            </span>
                          </div>
                        </Dropdown.Item>
                        <Dropdown.Item eventKey="backlog">
                          <div className="d-flex gap-1 align-items-center">
                            <img src={status} alt="progresss" />
                            <span
                              style={{ fontSize: "12px", fontWeight: "400" }}
                            >
                              Backlog
                            </span>
                          </div>
                        </Dropdown.Item>
                        <Dropdown.Item eventKey="done">
                          <div className="d-flex gap-1 align-items-center">
                            <img src={done} alt="done" />
                            <span
                              style={{ fontSize: "12px", fontWeight: "400" }}
                            >
                              Done
                            </span>
                          </div>
                        </Dropdown.Item>
                        <br />
                        <Dropdown.Item eventKey="due">
                          <div className="d-flex gap-1 align-items-center">
                            <img src={due} alt="due" />
                            <span
                              style={{ fontSize: "12px", fontWeight: "400" }}
                            >
                              Due
                            </span>
                          </div>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                    <ErrorMessage
                      name="status"
                      component="div"
                      className="text-danger"
                      style={{ fontSize: "12px" }}
                    />
                  </div>
                  {/* Priority Dropdown */}
                  <Dropdown
                    className="status-dropdown"
                    onSelect={(priority) => setFieldValue("priority", priority)}
                  >
                    <Dropdown.Toggle id="dropdown-basic">
                      <div className="d-flex gap-1 align-items-center">
                        {values.priority === "low" ? (
                          <img src={low} alt="done" />
                        ) : values.priority === "medium" ? (
                          <img src={medium} alt="done" />
                        ) : values.priority === "high" ? (
                          <img src={high} alt="done" />
                        ) : values.priority === "high" ? (
                          <img src={high} alt="done" />
                        ) : values.priority === "urgent" ? (
                          <img src={urgent} alt="done" />
                        ) : (
                          <img src={low} alt="done" />
                        )}

                        <span>
                          {" "}
                          {values.priority
                            ? values.priority.charAt(0).toUpperCase() +
                              values.priority.slice(1)
                            : "Select Priority"}
                        </span>
                      </div>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item eventKey="low">
                        <div className="d-flex gap-1 align-items-center">
                          <img src={low} alt="done" />
                          <span style={{ fontSize: "12px", fontWeight: "400" }}>
                            Low
                          </span>
                        </div>
                      </Dropdown.Item>
                      <Dropdown.Item eventKey="medium">
                        <div className="d-flex gap-1 align-items-center">
                          <img src={medium} alt="done" />
                          <span style={{ fontSize: "12px", fontWeight: "400" }}>
                            Medium
                          </span>
                        </div>
                      </Dropdown.Item>
                      <Dropdown.Item eventKey="high">
                        <div className="d-flex gap-1 align-items-center">
                          <img src={high} alt="done" />
                          <span style={{ fontSize: "12px", fontWeight: "400" }}>
                            High
                          </span>
                        </div>
                      </Dropdown.Item>

                      <Dropdown.Item eventKey="urgent">
                        <div className="d-flex gap-1 align-items-center">
                          <img src={urgent} alt="done" />
                          <span style={{ fontSize: "12px", fontWeight: "400" }}>
                            Urgent
                          </span>
                        </div>
                      </Dropdown.Item>

                      {/* <Dropdown.Item eventKey="Priority">
                        <div className="d-flex gap-1 align-items-center">
                          <img src={Priority} alt="Priority" />
                          <span style={{ fontSize: "12px", fontWeight: "400" }}>
                            Priority
                          </span>
                        </div>
                      </Dropdown.Item> */}
                    </Dropdown.Menu>
                  </Dropdown>
                  <ErrorMessage
                    name="priority"
                    component="div"
                    className="text-danger"
                    style={{ fontSize: "12px" }}
                  />

                  {/* Assignee Dropdown */}
                  <div>
                    {!editId && (
                      <Dropdown className="model_dropdown">
                        <Dropdown.Toggle id="dropdown-basic">
                          <div className="d-flex gap-1 align-items-center">
                            {/* <img
                            className="status-icon"
                            src={assign}
                            alt="assign"
                          /> */}
                            <span>
                              {values.assignees?.length
                                ? values.assignees.map((userId) => {
                                    const user = workspace.results.find(
                                      (user) => user.userId === userId
                                    );
                                    return (
                                      user && (
                                        <div
                                          key={user.userId}
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                            marginRight: 8,
                                          }}
                                        >
                                          <img
                                            src={user.photoURL}
                                            alt={user.displayName}
                                            style={{
                                              width: 20,
                                              height: 20,
                                              borderRadius: "50%",
                                              marginRight: 8,
                                            }}
                                          />
                                          <span>{user.displayName}</span>
                                        </div>
                                      )
                                    );
                                  })
                                : "Select Assignees"}
                            </span>
                          </div>
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <p>Members</p>
                          {workspace?.results?.map((item) => (
                            <Dropdown.Item
                              key={item.userId}
                              active={values.assignees?.includes(item.userId)}
                              onClick={() =>
                                setFieldValue(
                                  "assignees",
                                  toggleArrayItem(values.assignees, item.userId)
                                )
                              }
                              style={{ paddingBottom: "6px" }}
                            >
                              <img
                                src={item.photoURL}
                                alt={item.displayName}
                                style={{
                                  width: 20,
                                  height: 20,
                                  borderRadius: "50%",
                                  marginRight: 10,
                                }}
                              />
                              {item.displayName}
                            </Dropdown.Item>
                          ))}
                        </Dropdown.Menu>
                      </Dropdown>
                    )}
                    <ErrorMessage
                      name="assignees"
                      component="div"
                      className="text-danger"
                      style={{ fontSize: "12px" }}
                    />
                  </div>

                  {/* Date Picker */}
                  <Dropdown>
                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                      <div className="d-flex gap-1 align-items-center">
                        <img className="status-icon" src={date} alt="date" />
                        <span>
                          {values?.dueDate
                            ? new Date(values?.dueDate)
                                ?.toLocaleDateString("en-GB")
                                .replace(/\//g, "/")
                            : "Due Date"}
                        </span>
                      </div>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <div className="datepicker-wrapper">
                        <DatePicker
                          showTimeInput={true}
                          timeClassName={"time-valid"}
                          selected={values.dueDate}
                          value={values.dueDate}
                          onChange={(date) => {
                            setFieldValue("dueDate", new Date(date));
                            // setIsDateDropdownOpen(false); // Close dropdown after selecting a date
                          }}
                          inline
                        />
                      </div>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <Modal.Footer className="create-modal-footer">
                  {!editId ? (
                    <Dropdown className="status-dropdown">
                      <Dropdown.Toggle id="dropdown-basic">
                        <RiAttachment2 />
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        {values?.attachments?.map((item) => (
                          <Dropdown.Item>
                            <div className="w-100">
                              <div className="flex_box">
                                <div className="img_box">
                                  <img
                                    src={user.imageUrl || profileimg}
                                    alt="profile"
                                  />
                                  <p>{user?.fullName}</p>
                                </div>

                                <div className="button-box">
                                  {/* <button type="button">
                                  <img src={edit} alt="edit" />
                                </button> */}
                                  <button
                                    type="button"
                                    onClick={() =>
                                      setFieldValue(
                                        "attachments",
                                        values.attachments.filter(
                                          (i) =>
                                            i?.name !== item?.name &&
                                            i?.size !== item?.size
                                        )
                                      )
                                    }
                                  >
                                    <img src={deleteicon} alt="delete" />
                                  </button>
                                </div>
                              </div>
                              <p className="link_paragraph pt-1">
                                {item?.name}
                              </p>
                            </div>
                          </Dropdown.Item>
                        ))}
                        <div className="profile_inputs">
                          <div className="img_box gap-1">
                            <img
                              src={user.imageUrl || profileimg}
                              alt="profile"
                            />
                            <p>{user?.fullName}</p>
                          </div>
                          <div className="input_main_div">
                            <input
                              type="text"
                              disabled
                              value={file?.name || ""}
                              className="form-control"
                              placeholder="Document Name"
                            />
                          </div>
                          <div className="profile_inputs_footer">
                            <div className="button-box">
                              <label type="button">
                                <RiAttachment2 />
                                <input
                                  type="file"
                                  onChange={(e) => setFile(e.target.files[0])}
                                  style={{ display: "none" }}
                                />
                              </label>
                            </div>
                            <button
                              type="button"
                              onClick={() => {
                                if (!file) return;
                                setFieldValue("attachments", [
                                  ...values.attachments,
                                  file,
                                ]);
                                setFile(null);
                              }}
                              className="btn-style"
                            >
                              Add
                            </button>
                          </div>
                        </div>
                      </Dropdown.Menu>
                    </Dropdown>
                  ) : (
                    <div> &nbsp; </div>
                  )}
                  <div className="model-closing-btn">
                    <Button className="modal-cancel-btn" onClick={handleClose}>
                      Cancel
                    </Button>
                    <Button
                      disabled={loading}
                      className="btn-style"
                      type="submit"
                    >
                      {loading ? (
                        <Spinner size="sm" />
                      ) : editId ? (
                        "Update Project"
                      ) : (
                        "Create Project"
                      )}
                    </Button>
                  </div>
                </Modal.Footer>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default CreateProjectModal;
