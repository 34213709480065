import React from "react";
import project from "../assets/images/icons/ph_cube-bold.svg";
import add from "../assets/images/icons/add.svg";
import checklistIcon from "../assets/images/icons/checklist-icon.svg";
import overview from "../assets/images/icons/overview-icon.svg";
import { FaChevronRight } from "react-icons/fa";
import { Link, useParams } from "react-router-dom";
import { handleModel } from "../redux/layoutSlices/modelSlice";
import { useDispatch, useSelector } from "react-redux";

export default function ClintTopBar() {
  const d = useDispatch();
  const { id } = useParams();
  return (
    <>
      <div className="main-header">
        <div className="row d-flex align-items-baseline">
        <div className="col-md-6 col-sm-8 col-12 ps-0">
            <div className="header">
              <div className="project-heading">
                <strong>Clients</strong>
              
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="flex-wrapper">
              <div className="create-sec">
                <button
                  onClick={() =>
                    d(
                      handleModel({
                        model: "CreateClintModel",
                        state: true,
                        args: {},
                      })
                    )
                  }
                >
                  <img src={add} alt="Add icon" />
                  <span>Create Client</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
