/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  assignTaskToUsersAsyncThunk,
  deleteTaskAsyncThunk,
  editTaskAsyncThunk,
  getAssignTaskAsyncThunk,
  getProjectTasksAsyncThunk,
  unAssignTaskToUsersAsyncThunk,
} from "../redux/pagesSlices/taskSlice";
import { Dropdown } from "react-bootstrap";
import statusicon2 from "../assets/images/icons/statusicon2.svg";
import statusicon3 from "../assets/images/icons/statusicon3.svg";
import statusicon4 from "../assets/images/icons/statusicon4.svg";
import { GrAttachment } from "react-icons/gr";
import DeleteModal from "./DeleteModal";
import del from "../assets/images/icons/del.svg";
import avatar2 from "../assets/images/emojis/user-avatar-2.png";
import { FaRegEdit } from "react-icons/fa";
import { handleModel } from "../redux/layoutSlices/modelSlice";
import { BsThreeDotsVertical } from "react-icons/bs";
import DropdownButton from "react-bootstrap/DropdownButton";
import NodataFound from "./NodataFound";
import Loadingdata from "../pages/loadingdata";
import low from "../assets/images/icons/low.png";
import urgent from "../assets/images/icons/svg9.svg";
import medium from "../assets/images/icons/svg7.svg";
import high from "../assets/images/icons/svg8.svg";
import { BsRecordCircle } from "react-icons/bs";
import todo from "../assets/images/icons/status1.svg";
import due from "../assets/images/icons/x-Vector.svg";
import {
  getProjectAsyncThunk,
  getWorkSpaceAsyncThunk,
} from "../redux/pagesSlices/projectSlice";
import { TiTick } from "react-icons/ti";
import { FcCancel } from "react-icons/fc";
import { toast } from "react-toastify";

const statusIcon = {
  todo: <img src={todo} alt="todo" />, // React icon
  in_progress: <img src={statusicon2} alt="In progress" />, // Custom SVG icon
  backlog: <img src={statusicon3} alt="Backlog" />, // Custom SVG icon
  done: <img src={statusicon4} alt="Done" />, // Custom SVG icon
  due: <img src={due} alt="Due" />,
};
const priorityIcon = {
  low: <img src={low} alt="Low" />, // React icon
  medium: <img src={medium} alt="Medium" />, // Custom SVG icon
  high: <img src={high} alt="High" />, // Custom SVG icon
  urgent: <img src={urgent} alt="Urgent" />, // Custom SVG icon
};

function ProjectTask() {
  const id = useParams().id;
  const d = useDispatch();
  const Task = useSelector((state) => state?.task?.ProjectTasks);
  const userRole = useSelector((state) => state.workspace.userRole);
  const loading = useSelector(
    (state) => state?.task?.loadings?.getProjectTasksAsyncThunk
  );

  const workspace = useSelector((state) => state.project.WorkSpace);

  useEffect(() => {
    if (!workspace.data || workspace.data.length === 0) {
      d(getWorkSpaceAsyncThunk({}));
    }
  }, [d, workspace.data]);

  useEffect(() => {
    d(
      getAssignTaskAsyncThunk({
        params: { page: 1, limit: 1000 },
        id: id,
      })
    );
  }, []);

  const handleAssignees = (id, user) => {
    d(
      assignTaskToUsersAsyncThunk({
        id,
        data: { assignees: [user.userId] },
        user,
        callBack: () => {},
      })
    );
  };

  const handleUnAssignees = (id, userId) => {
    d(
      unAssignTaskToUsersAsyncThunk({
        id,
        data: { assignees: [userId] },
        userId,
        callBack: () => {},
      })
    );
  };

  const getData = () => {
    d(
      getProjectTasksAsyncThunk({
        params: { page: 1, limit: 1000 },
        id: id,
      })
    );
  };

  useEffect(() => {
    getData();
  }, [d]);

  const deleteTask = (taskId) => {
    if (userRole?.role === "org:admin") {
      d(
        deleteTaskAsyncThunk({
          id: taskId,
          callBack: () => {
            getData();
            d(getProjectAsyncThunk({ id }));
          },
        })
      );
    } else {
      toast.error("You don't have permission to do this.");
    }
  };

  const editTask = (item) => {
    if (userRole?.role === "org:admin") {
      d(
        handleModel({
          model: "CreateTaskModal",
          state: true,
          args: {
            id: id,
            editId: item?.id,
            formData: item,
          },
        })
      );
    } else {
      toast.error("You don't have permission to do this.");
    }
  };

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const handleUpdate = (id, key, value) => {
    d(
      editTaskAsyncThunk({
        id,
        data: {
          [key]: value,
        },
        callBack: () => {
          // getData();
        },
      })
    );
  };

  const formatDate = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    const options = { year: "numeric", month: "long", day: "numeric" };
    return date.toLocaleDateString("en-US", options);
  };

  return (
    <>
      <div className="task-table-container pt-0">
        <table className="task-table">
          {loading ? (
            <Loadingdata />
          ) : Task?.results?.length === 0 ? (
            <NodataFound title={"Tasks"} />
          ) : (
            <>
              <thead>
                <tr>
                  <th>Tasks</th>
                  <th>Assignee</th>
                  <th>Due Date</th>
                  <th>Status</th>
                  <th>Priority</th>
                  <th>Attachment</th>
                </tr>
              </thead>
              <tbody>
                <>
                  {Task?.results?.map((task, idx) => (
                    <tr key={idx}>
                      <td>
                        <div className="icon-wrapper">
                          {task.status === "done" ? (
                            <BsRecordCircle className="icon-task-status-done" />
                          ) : task.status === "todo" ? (
                            <BsRecordCircle className="icon-task-status-todo" />
                          ) : task.status === "backlog" ? (
                            <BsRecordCircle className="icon-task-status-backlog" />
                          ) : task.status === "in_progress" ? (
                            <BsRecordCircle className="icon-task-status-inprogress" />
                          ) : null}

                          <p>{task.name}</p>
                        </div>
                      </td>
                      <td className="assignee mt-0">
                        <Dropdown
                          onSelect={(e) =>
                            JSON.parse(e).type
                              ? handleUnAssignees(
                                  task.id,
                                  JSON.parse(e)?.user?.userId
                                )
                              : handleAssignees(task.id, JSON.parse(e).user)
                          }
                          className="dropdown-wraper project-assign-dropdown"
                        >
                          <Dropdown.Toggle>
                            {task.assignees && task.assignees.length > 0 ? (
                              <>
                                {task.assignees
                                  .slice(0, 3)
                                  .map((assignee, idx) => (
                                    <img
                                      key={idx}
                                      src={assignee.user.picture || avatar2}
                                      alt="Assignee"
                                    />
                                  ))}
                                {task.assignees.length > 3 && (
                                  <div className="avatar-count">
                                    <strong>
                                      <span>+{task.assignees.length - 3}</span>
                                    </strong>
                                  </div>
                                )}
                              </>
                            ) : (
                              <div className="assignee-images">
                                <FcCancel size={40} />
                              </div>
                            )}
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            {workspace?.results?.map((assignee, key) => (
                              <Dropdown.Item
                                key={assignee?.userId}
                                eventKey={JSON.stringify({
                                  user: assignee,
                                  // userId: assignee?.userId,
                                  type: task?.assignees?.some(
                                    (ite) => assignee?.userId === ite?.userId
                                  ),
                                })}
                              >
                                {task?.assignees?.some(
                                  (ite) => assignee?.userId === ite?.userId
                                ) ? (
                                  <>
                                    <TiTick color="green" size={20} />
                                  </>
                                ) : (
                                  ""
                                )}
                                <div className="status-icon">
                                  <img src={assignee?.picture} alt="Assignee" />
                                  <p>{assignee?.displayName}</p>
                                </div>
                              </Dropdown.Item>
                            ))}
                          </Dropdown.Menu>
                        </Dropdown>
                      </td>
                      <td>
                        {/* Display only the date part */}
                        {formatDate(task.dueDate)}
                      </td>
                      <td>
                        <Dropdown
                          className="dropdown-wraper"
                          onSelect={(key) =>
                            handleUpdate(task.id, "status", key)
                          }
                        >
                          <Dropdown.Toggle className="status-icon">
                            {statusIcon[task.status]}
                            <p className="pb-0">
                              {task.status === "in_progress"
                                ? "In Progress"
                                : task.status}
                            </p>
                          </Dropdown.Toggle>
                          <Dropdown.Menu className="dropdown-menu-right">
                            <Dropdown.Item eventKey={"todo"}>
                              <div className="status-icon">
                                {statusIcon["todo"]}
                                <p>todo</p>
                              </div>
                            </Dropdown.Item>
                            <Dropdown.Item eventKey={"in_progress"}>
                              <div className="status-icon">
                                {statusIcon["in_progress"]}
                                <p>In progress</p>
                              </div>
                            </Dropdown.Item>

                            <Dropdown.Item eventKey={"backlog"}>
                              <div className="status-icon">
                                {statusIcon["backlog"]}
                                <p>Backlog</p>
                              </div>
                            </Dropdown.Item>
                            <Dropdown.Item eventKey={"done"}>
                              <div className="status-icon">
                                {statusIcon["done"]}
                                <p>done</p>
                              </div>
                            </Dropdown.Item>
                            <Dropdown.Item eventKey={"due"}>
                              <div className="status-icon">
                                {statusIcon["due"]}
                                <p>Due</p>
                              </div>
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </td>
                      <td>
                        <Dropdown
                          className="dropdown-wraper"
                          onSelect={(key) =>
                            handleUpdate(task.id, "priority", key)
                          }
                        >
                          <Dropdown.Toggle className="status-icon">
                            {priorityIcon[task.priority]}
                            <p className="pb-0">{task.priority}</p>
                          </Dropdown.Toggle>
                          <Dropdown.Menu className="dropdown-menu-right">
                            <Dropdown.Item eventKey={"low"}>
                              <div className="status-icon">
                                {priorityIcon["low"]}
                                <p>Low</p>
                              </div>
                            </Dropdown.Item>
                            <Dropdown.Item eventKey={"medium"}>
                              <div className="status-icon">
                                {priorityIcon["medium"]}
                                <p>Medium</p>
                              </div>
                            </Dropdown.Item>

                            <Dropdown.Item eventKey={"high"}>
                              <div className="status-icon">
                                {priorityIcon["high"]}
                                <p>High</p>
                              </div>
                            </Dropdown.Item>
                            <Dropdown.Item eventKey={"urgent"}>
                              <div className="status-icon">
                                {priorityIcon["urgent"]}
                                <p>Urgent</p>
                              </div>
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </td>
                      <td>
                        <div className="icons-wrapper d-flex gap-4 justify-content-between">
                          <div className="icon-wrapper">
                            <GrAttachment />
                            <p>{task.attachment}</p>
                          </div>
                          <div className="icon-wrapper">
                            <DropdownButton
                              // as={ButtonGroup}
                              id={`dropdown-button-drop-start`}
                              drop={"start"}
                              variant="secondary"
                              title={<BsThreeDotsVertical />}
                              className="custom-dropdown-toggle"
                            >
                              <Dropdown.Item
                                eventKey="1"
                                onClick={() => editTask(task)}
                              >
                                {" "}
                                <FaRegEdit />
                                <span>Edit</span>
                              </Dropdown.Item>
                              <Dropdown.Item
                                eventKey="2"
                                onClick={() => deleteTask(task.id)}
                              >
                                {" "}
                                <img src={del} alt="delete" />
                                <span>Delete</span>
                              </Dropdown.Item>

                              {/* <Dropdown.Item
                                eventKey="2"
                                onClick={() => AssignTaskToUser(task)}
                              >
                                {" "}
                                <MdAssignment />
                                <span>Assign</span>
                              </Dropdown.Item> */}
                            </DropdownButton>

                            {/* <Dropdown className="actions-dropdown">
                      <Dropdown.Toggle
                        style={{
                          background: "transparent",
                          border: "none",
                          color: "black",
                        }}
                        id="dropdown-basic"
                        className="custom-dropdown-toggle"
                      >
                        
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="delete-button">
                        <Dropdown.Item
                          href=""
                          onClick={() => editProject(task)}
                        >
                      
                        </Dropdown.Item>
                        <Dropdown.Item
                          href=""
                          
                        >
                        
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown> */}
                          </div>
                        </div>
                      </td>
                      {/* <td
              
            </td> */}
                    </tr>
                  ))}
                </>
              </tbody>
            </>
          )}
        </table>
        <DeleteModal show={show} handleClose={handleClose} />
      </div>

      <div class="modal" tabindex="-1">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Modal title</h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <p>Modal body text goes here.</p>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button type="button" class="btn btn-primary">
                Save changes
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
    // </div>
  );
}

export default ProjectTask;
