/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useMemo, useState } from "react";
import complyaLogo from "../assets/images/icons/logo-square.png";
import dashboard from "../assets/images/icons/dashboard.svg";
import Dropdown from "react-bootstrap/Dropdown";
import search from "../assets/images/icons/search.svg";
import dashbord from "../assets/images/icons/dash.svg";
import inbox from "../assets/images/icons/inbox.svg";
import projects from "../assets/images/icons/projects.svg";
import task from "../assets/images/icons/tasks.svg";
import docs from "../assets/images/icons/docs.svg";
import team from "../assets/images/icons/team.svg";
import help from "../assets/images/icons/help.svg";
import { UserButton, useUser } from "@clerk/clerk-react";
import { useLocation, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  setSelectedWorkspace,
  setUserRoleInOrg,
} from "../redux/pagesSlices/workspaceSlice";
import { MdKeyboardArrowDown } from "react-icons/md";
import { CiSettings } from "react-icons/ci";
import { IoHomeOutline } from "react-icons/io5";
import { FaUsers } from "react-icons/fa6";
import { PiNotificationBold } from "react-icons/pi";
import { FaBars } from "react-icons/fa";
import { FaPlus } from "react-icons/fa";
import { PiSignOut } from "react-icons/pi";
import { FaUserGroup } from "react-icons/fa6";
import { useClerk } from "@clerk/clerk-react";

// Initialization outside component to avoid recreation on each render
const paths = [
  {
    icon: <img src={dashbord} alt="Inbox" />,
    title: "Dashboard",
    path: "/",
    toolTipClass: "dashboard-tooltip",
  },
  {
    icon: <img src={inbox} alt="Inbox" />,
    title: "Inbox",
    path: "/inbox",
    toolTipClass: "inbox-tooltip",
  },
  {
    icon: <img src={projects} alt="Inbox" />,
    title: "Sessions",
    path: "/session",
    toolTipClass: "projects-tooltip",
  },
  {
    icon: <img src={task} alt="Inbox" />,
    title: "Tasks",
    path: "/tasks",
    toolTipClass: "tasks-tooltip",
  },
  {
    icon: <img src={docs} alt="Inbox" />,
    title: "Files",
    path: "/Files",
    toolTipClass: "docs-tooltip",
  },
  {
    icon: <img src={dashbord} alt="Inbox" />,
    title: "Clients",
    path: "/client",
    toolTipClass: "docs-tooltip",
  },
];

const workspacePaths = [
  { icon: <CiSettings />, title: "General", path: "/workspace/general" },
  { icon: <FaUsers />, title: "Staff", path: "/workspace/staff" },
  {
    icon: <PiNotificationBold />,
    title: "Notification",
    path: "/workspace/notification",
  },
];

export default function Sidebar() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { signOut } = useClerk();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [sidebarSize, setSidebarSize] = useState(false);
  const [activeTab, setActiveTab] = useState(location.pathname);

  const { user } = useUser();
  const selectedWorkSpace = useSelector((state) => state.workspace.workspace);

  const organizations = useMemo(
    () => user?.organizationMemberships?.map((org) => org) || [],
    [user]
  );

  const handleClick = useCallback(
    (path) => {
      setActiveTab(path);
      navigate(path);
    },
    [navigate]
  );

  const [searchTabs, setSearchTabs] = useState(
    location.pathname.includes("workspace") ? workspacePaths : paths
  );
  useEffect(() => {
    if (location.pathname.includes("workspace")) {
      setSearchTabs(workspacePaths);
    } else {
      setSearchTabs(paths);
    }
  }, [location.pathname]);

  useEffect(() => {
    if (organizations.length) {
      const localOrg = JSON.parse(localStorage.getItem("workspace"));
      const org =
        organizations.find((org) => org.organization.id === localOrg?.id) ||
        organizations[0];
      if (org.id !== selectedWorkSpace?.id) {
        dispatch(setSelectedWorkspace(org.organization));
        dispatch(setUserRoleInOrg({...org, organization: null}));
      }
    }
  }, [organizations, selectedWorkSpace, dispatch]);

  const toggleSidebar = () => setIsSidebarOpen((prev) => !prev);
  const toggleSidebarSize = () => setSidebarSize((prev) => !prev);

  return (
    <>
      <div className="hamburger d-lg-none d-block">
        <FaBars size={20} onClick={toggleSidebar} />
      </div>
      <div
        className={`sidebar-main-wrapper ${
          sidebarSize ? "sidebar-short" : ""
        } ${isSidebarOpen ? "active" : ""}`}
      >
        {/* sidebar-short */}
        <div className="sidebar-header">
          <div className="sidebar-logo">
            <Dropdown
              onSelect={(selected) => {
                console.log("🚀 ~ Sidebar ~ selected:", selected);
                if (selected) {
                  const org = organizations.find((org1) => org1.organization.id === selected);
                  dispatch(setSelectedWorkspace(org.organization));
                  dispatch(setUserRoleInOrg({...org, organization: null}));
                }
              }}
              className="sidebar_dropdown"
            >
              <Dropdown.Toggle
                variant="success"
                id="dropdown-basic"
                className="d-flex align-items-center gap-1"
              >
                <div className="logo">
                  <img
                    src={selectedWorkSpace?.imageUrl || complyaLogo}
                    alt="Logo"
                  />
                </div>
                <span>
                  {" "}
                  {selectedWorkSpace?.name}
                  <MdKeyboardArrowDown />
                </span>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {organizations.map((org) => (
                  <Dropdown.Item
                    key={org.organization.id}
                    eventKey={org.organization.id}
                    className="logo_item"
                  >
                    <div className="d-flex align-items-center gap-2">
                      <div className="logo">
                        <img
                          src={org?.organization?.imageUrl || complyaLogo}
                          alt="Logo"
                        />
                      </div>
                      <span style={{ fontSize: "16px" }}>
                        {org?.organization.name}
                      </span>
                    </div>
                  </Dropdown.Item>
                ))}
                <div className="dropdown-divider"></div>
                <Dropdown.Item>
                  <Link to={"/create/workspace"}>
                    <div className="d-flex align-items-center gap-2">
                      <div className="logo">
                        <FaPlus />
                      </div>
                      Add new workplace
                    </div>
                  </Link>
                </Dropdown.Item>
                <Dropdown.Item>
                  <Link
                    to={
                      location.pathname === "/workspace/general"
                        ? "/"
                        : "/workspace/general"
                    }
                  >
                    <div className="d-flex align-items-center gap-2">
                      <div className="logo">
                        <CiSettings />
                      </div>
                      Workspace Setting
                    </div>
                  </Link>
                </Dropdown.Item>
                <div className="dropdown-divider"></div>
                <Dropdown.Item>
                  <div
                    className="d-flex align-items-center gap-2"
                    onClick={() => signOut()}
                  >
                    <div className="logo">
                      <PiSignOut />
                    </div>
                    Sign Out
                  </div>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div
            className="dashboard-logo"
            onClick={() => {
              toggleSidebar();
              toggleSidebarSize();
            }}
          >
            <>
              <img src={dashboard} alt="Dashboard Logo" />
            </>
          </div>
        </div>

        <div className="search-box">
          <div className="search-field">
            <input
              onChange={(e) =>
                setSearchTabs(
                  (location.pathname.includes("/workspace/")
                    ? workspacePaths
                    : paths
                  ).filter((path) =>
                    path.title
                      .toLowerCase()
                      .includes(e.target.value.toLowerCase())
                  )
                )
              }
              type="text"
              placeholder="Quick actions"
            />
          </div>
          <div className="search-logo" onClick={() => handleClick("/")}>
            <img src={search} alt="Search Logo" />
            <div className="slash">
              <span>/</span>
            </div>
          </div>
        </div>

        <div className="tab-section">
          <ul>
            {searchTabs.map((path) => (
              <li
                onClick={() => handleClick(path.path)}
                className={`${
                  location.pathname === path.path ? "active" : ""
                } ${path.toolTipClass}`}
              >
                <Link to={path.path}>
                  <div className="tab-logo">{path.icon}</div>
                  <span>{path.title}</span>
                </Link>
              </li>
            ))}
          </ul>
        </div>

        <div className="sidebar-footer">
          <div className="tab-section">
            <ul className="management-tooltip">
              <li
                // onClick={() => handleClick(location.pathname === "/workspace/general" ? "/" : "/workspace/general")}
                className={activeTab === "/workspace-setting" ? "active" : ""}
                style={{ cursor: "pointer" }}
              >
                <Link
                  to={
                    location.pathname === "/workspace/general"
                      ? "/"
                      : "/workspace/general"
                  }
                >
                  <div className="tab-logo">
                    {/* <img src={team} alt="Inbox" /> */}
                    {location.pathname === "/workspace/general" ? (
                      <IoHomeOutline />
                    ) : (
                      // <CiSettings />
                      ""
                    )}
                  </div>
                  <span>
                    {location.pathname === "/workspace/general"
                      ? "Back to Home"
                      : ""}
                  </span>
                </Link>
              </li>
              <li
                onClick={() => handleClick("/invitation")}
                className={activeTab === "/Invite-Teammates" ? "active" : ""}
              >
                <Link to="invitation">
                  <div className="tab-logo">
                    <img src={team} alt="Inbox" />
                  </div>
                  <span>Invite Teammates</span>
                </Link>
              </li>

              <li>
                <a>
                  <div className="tab-logo">
                    <img src={help} alt="Inbox" />
                  </div>
                  <span>Help</span>
                </a>
              </li>
            </ul>
          </div>

          <div className="sidebar-header">
            <div className="logo-section">
              <Dropdown className="sidebar_dropdown">
                <Dropdown.Toggle
                  variant="success"
                  id="dropdown-basic"
                  className="d-flex align-items-center"
                >
                  <div className="logo">
                    <img src={user?.imageUrl || complyaLogo} alt="Logo" />
                  </div>
                  <span>
                    {" "}
                    {user?.fullName || user?.emailAddresses?.[0]?.emailAddress}
                    <MdKeyboardArrowDown />
                  </span>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item className="dropdown-item">
                    <Link
                      to="/account-settings"
                      className="d-flex align-items-center gap-2"
                    >
                      <div className="logo">
                        <FaUserGroup />
                      </div>
                      Account Settings
                    </Link>
                  </Dropdown.Item>
                  <Dropdown.Item>
                    <div
                      className="d-flex align-items-center gap-2"
                      onClick={() => signOut()}
                    >
                      <div className="logo">
                        <PiSignOut />
                      </div>
                      Sign Out
                    </div>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </div>
        <div className="sidebar-footer">
          <div className="tab-section">
            <ul className="management-tooltip">
              <li
                // onClick={() => handleClick(location.pathname === "/workspace/general" ? "/" : "/workspace/general")}
                className={activeTab === "/workspace-setting" ? "active" : ""}
                style={{ cursor: "pointer" }}
              >
                <Link
                  to={
                    location.pathname === "/workspace/staff"
                      ? "/"
                      : "/workspace/general"
                  }
                >
                  <div className="tab-logo">
                    {/* <img src={team} alt="Inbox" /> */}
                    {location.pathname === "/workspace/staff" ? (
                      <IoHomeOutline />
                    ) : (
                      // <CiSettings />
                      ""
                    )}
                  </div>
                  <span>
                    {location.pathname === "/workspace/staff"
                      ? "Back to Home"
                      : ""}
                  </span>
                </Link>
              </li>
              <li
                onClick={() => handleClick("/invitation")}
                className={activeTab === "/Invite-Teammates" ? "active" : ""}
              >
                <Link to={"invitation"}>
                  <div className="tab-logo">
                    <img src={team} alt="Inbox" />
                  </div>
                  <span>Invite Teammates</span>
                </Link>
              </li>

              <li>
                <a>
                  <div className="tab-logo">
                    <img src={help} alt="Inbox" />
                  </div>
                  <span>Help</span>
                </a>
              </li>
            </ul>
          </div>

          <div className="sidebar-header">
            <div className="logo-section">
              <Dropdown className="sidebar_dropdown">
                <Dropdown.Toggle
                  variant="success"
                  id="dropdown-basic"
                  className="d-flex align-items-center"
                >
                  <div className="logo">
                    <img src={user?.imageUrl || complyaLogo} alt="Logo" />
                  </div>
                  <span>
                    {" "}
                    {user?.fullName || user?.emailAddresses?.[0]?.emailAddress}
                    <MdKeyboardArrowDown />
                  </span>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item className="dropdown-item">
                    <Link
                      to="/account-settings"
                      className="d-flex align-items-center gap-2"
                    >
                      <div className="logo">
                        <FaUserGroup />
                      </div>
                      Account Settings
                    </Link>
                  </Dropdown.Item>
                  <Dropdown.Item>
                    <div
                      className="d-flex align-items-center gap-2"
                      onClick={() => signOut()}
                    >
                      <div className="logo">
                        <PiSignOut />
                      </div>
                      Sign Out
                    </div>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </div>
        <div className="sidebar-footer">
          <div className="tab-section">
            <ul className="management-tooltip">
              <li
                // onClick={() => handleClick(location.pathname === "/workspace/general" ? "/" : "/workspace/general")}
                className={activeTab === "/workspace-setting" ? "active" : ""}
                style={{ cursor: "pointer" }}
              >
                <Link
                  to={
                    location.pathname === "/workspace/notification"
                      ? "/"
                      : "/workspace/general"
                  }
                >
                  <div className="tab-logo">
                    {/* <img src={team} alt="Inbox" /> */}
                    {location.pathname === "/workspace/notification" ? (
                      <IoHomeOutline />
                    ) : (
                      // <CiSettings />
                      ""
                    )}
                  </div>
                  <span>
                    {location.pathname === "/workspace/notification"
                      ? "Back to Home"
                      : ""}
                  </span>
                </Link>
              </li>
              <li
                onClick={() => handleClick("/invitation")}
                className={activeTab === "/Invite-Teammates" ? "active" : ""}
              >
                <Link to={"/invitation"}>
                  <div className="tab-logo">
                    <img src={team} alt="Inbox" />
                  </div>
                  <span>Invite Teammates</span>
                </Link>
              </li>

              <li>
                <a>
                  <div className="tab-logo">
                    <img src={help} alt="Inbox" />
                  </div>
                  <span>Help</span>
                </a>
              </li>
            </ul>
          </div>

          <div className="sidebar-header">
            <div className="logo-section">
              <Dropdown className="sidebar_dropdown">
                <Dropdown.Toggle
                  variant="success"
                  id="dropdown-basic"
                  className="d-flex align-items-center"
                >
                  <div className="logo">
                    <img src={user?.imageUrl || complyaLogo} alt="Logo" />
                  </div>
                  <span
                    title={
                      user?.fullName || user?.emailAddresses?.[0]?.emailAddress
                    }
                  >
                    {" "}
                    {user?.fullName || user?.emailAddresses?.[0]?.emailAddress}
                  </span>
                  <MdKeyboardArrowDown />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item className="dropdown-item">
                    <Link
                      to="/account-settings"
                      className="d-flex align-items-center gap-2"
                    >
                      <div className="logo">
                        <FaUserGroup />
                      </div>
                      Account Settings
                    </Link>
                  </Dropdown.Item>
                  <Dropdown.Item>
                    <div
                      className="d-flex align-items-center gap-2"
                      onClick={() => signOut()}
                    >
                      <div className="logo">
                        <PiSignOut />
                      </div>
                      Sign Out
                    </div>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
