import React from "react";
import project from "../../src/assets/images/project.png";
import { FaRegFile } from "react-icons/fa";
import { handleModel } from "../redux/layoutSlices/modelSlice";
import { useDispatch, useSelector } from "react-redux";

function NoProjectFound({ handleCreateProjectShow }) {
  const d = useDispatch();
  const userRole = useSelector((state) => state.workspace.userRole);

  return (
    <div className="no-project-found project_page">
      <div className="image-wrapper">
        <img src={project} alt="project" />
        <div className="project-content">
          <h6>No Session Found</h6>
          <p>
            There aren't currently any Sessions here.<br></br>
            Create to keep track of your Session.
          </p>
          {userRole?.role === "org:admin" && (
          <div className="project-icon">
            <button
              onClick={() => {
                d(
                  handleModel({
                    model: "CreateProjectModal",
                    state: true,
                    args: {},
                  })
                );
              }}
              disabled={userRole?.role !== "org:admin"}
              href="#"
              className="btn-style"
            >
              <FaRegFile /> Create Session
            </button>
          </div>
            )}
        </div>
      </div>
    </div>
  );
}

export default NoProjectFound;
