import { createSlice, createAsyncThunk, isAnyOf } from "@reduxjs/toolkit";
import { ApiRequests } from "../../service/ApiRequests";
import {
  catchAsync,
  detectError,
  handleLoadingErrorParamsForAsycThunk,
  reduxToolKitCaseBuilder,
} from "../../helpers/detectError";
import { toast } from "react-toastify";
// import { useNavigate } from "react-router-dom";
// import { red } from "@mui/material/colors";

// Start User Slices
///////////////////////////////////////////////////

export const createProjectAsyncThunk = createAsyncThunk(
  "user/createProjectAsyncThunk",
  catchAsync(async ({ data, callBack }, { dispatch, getState }) => {
    const response = await ApiRequests.creatProject(data);
    if (response.status === 201) {
      toast.success("Project Created Successfully!");
    }
    if (callBack) await callBack();
    return response?.data;
  })
);
export const getProjectAsyncThunk = createAsyncThunk(
  "user/getProjectAsyncThunk",
  catchAsync(async ({ id, callBack }, { dispatch, getState }) => {
    const response = await ApiRequests.getProject(id);
    if (callBack) callBack(response?.data);
    return response?.data;
  })
);
export const editProjectAsyncThunk = createAsyncThunk(
  "user/editProjectAsyncThunk",
  catchAsync(async ({ id, data, callBack }, { dispatch, getState }) => {
    const response = await ApiRequests.updateProject({ id, data });
    // if (response.status === 200) {
    // toast.success("Project Updated Successfully!");
    // }
    if (callBack) await callBack();

    return response?.data;
  })
);

export const getProjectsAsyncThunk = createAsyncThunk(
  "user/getProjectsAsyncThunk",
  catchAsync(async ({ params }, { dispatch, getState }) => {
    const response = await ApiRequests.getProjectsListing(params);
    return response?.data;
  })
);

export const deleteProjectAsyncThunk = createAsyncThunk(
  "user/deleteProjectAsyncThunk",
  catchAsync(async ({ id, callBack }, { dispatch, getState }) => {
    const response = await ApiRequests.deleteProject(id);
    if (response.status === 204) {
      toast.success("Session Deleted Successfully!");
    }
    if (callBack) await callBack();
    return id;
  })
);

export const assignProjectToUsersAsyncThunk = createAsyncThunk(
  "project/assignProjectToUsersAsyncThunk",
  catchAsync(async ({ id, data, callBack, user }, { dispatch, getState }) => {
    const response = await ApiRequests.assignProjectToUsers(id, data);
    if (response.status === 200) {
      toast.success("Session Assigned Successfully!");
    }
    if (callBack) await callBack();
    return { data: response?.data, user, id };
  })
);

export const unAssignProjectToUsersAsyncThunk = createAsyncThunk(
  "project/unAssignProjectToUsersAsyncThunk",
  catchAsync(async ({ id, data, callBack, userId }, { dispatch, getState }) => {
    const response = await ApiRequests.unAssignProjectToUsers(id, data);
    if (response.status === 200) {
      toast.success("Session UnAssigned Successfully!");
    }
    if (callBack) await callBack();
    return { data: response?.data, id, userId };
  })
);

// GET WORKSPACE
export const getWorkSpaceAsyncThunk = createAsyncThunk(
  "user/getWorkSpaceAsyncThunk",
  catchAsync(async ({ params }, { dispatch, getState }) => {
    const response = await ApiRequests.getWorkSpace(params);
    return response?.data;
  })
);

export const getWorkSpaceStaffAsyncThunk = createAsyncThunk(
  "user/getWorkSpaceStaffAsyncThunk",
  catchAsync(async ({ params }, { dispatch, getState }) => {
    const response = await ApiRequests.getWorkSpaceStaff(params);
    return response?.data;
  })
);

///////////////////////////////////////////////////

const initialState = {
  //news states
  projects: {
    page: 0,
    results: [],
    totalPages: 1,
  },
  WorkSpace: {
    page: 0,
    results: [],
    totalPages: 1,
  },
  WorkSpaceStaff: {
    page: 0,
    results: [],
    totalPages: 1,
  },
  project: {},
  story: null,
  assets: null,
  asset: null,
  listings: {
    page: 0,
    results: [],
    totalPages: 1,
  },
  // manager states
  errors: {},
  loadings: {},
  errorMessages: {},
  errorCodes: {},
  paramsForThunk: {},
  search: null,
  categoryId: null,
  categories: [],
  order: "asce",
  userIds: [],
};

const userSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    setSearchValue(state, action) {
      state.search = action.payload;
    },
    setCategoryValue(state, action) {
      state.categoryId = action.payload;
    },
    setOrderValue(state, action) {
      state.order = action.payload;
    },
    storeUserIds: (state, action) => {
      state.userIds = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      //

      .addCase(getProjectsAsyncThunk.fulfilled, (state, action) => {
        if (action.payload?.page > 1) {
          state.projects = {
            ...action.payload,
            projects: state?.projects?.results.concat(action?.payload?.results),
          };
        } else {
          state.projects = action.payload;
        }
      })
      .addCase(editProjectAsyncThunk.fulfilled, (state, action) => {
        console.log("🚀 ~ .addCase ~ action.payload:", action.payload);

        state.projects = {
          ...state.projects,
          results: state.projects?.results.map((e) => {
            if (e.id === action.payload.id) {
              return {
                ...action.payload,
                Task: e.Task,
                assignees: e.assignees,
                _count: e._count,
              };
            } else {
              return e;
            }
          }),
        };
      })
      .addCase(getWorkSpaceAsyncThunk.fulfilled, (state, action) => {
        if (action.payload?.page > 1) {
          state.WorkSpace = {
            ...action.payload,
            WorkSpace: state?.WorkSpace?.results.concat(
              action?.payload?.results
            ),
          };
        } else {
          state.WorkSpace = action.payload;
        }
      })

      .addCase(getWorkSpaceStaffAsyncThunk.fulfilled, (state, action) => {
        if (action.payload?.page > 1) {
          state.WorkSpaceStaff = {
            ...action.payload,
            WorkSpaceStaff: state?.WorkSpaceStaff?.results.concat(
              action?.payload?.results
            ),
          };
        } else {
          state.WorkSpaceStaff = action.payload;
        }
      })


      .addCase(getProjectAsyncThunk.fulfilled, (state, action) => {
        state.project = action.payload;
      })
      .addCase(assignProjectToUsersAsyncThunk.fulfilled, (state, action) => {
        state.projects = {
          ...state.projects,
          results: state.projects?.results.map((e) => {
            if (e.id === action.payload.id) {
              return {
                ...e,
                assignees: [
                  ...e.assignees,
                  {
                    createdAt: new Date(),
                    projectId: e.id,
                    updatedAt: new Date(),
                    user: action.payload.user,
                    userId: action.payload?.user?.userId,
                  },
                ],
              };
            } else {
              return e;
            }
          }),
        };
      })
      .addCase(unAssignProjectToUsersAsyncThunk.fulfilled, (state, action) => {
        state.projects = {
          ...state.projects,
          results: state.projects?.results.map((e) => {
            if (e.id === action.payload.id) {
              return {
                ...e,
                assignees: e.assignees.filter(
                  (user) => user.userId !== action.payload.userId
                ),
              };
            } else {
              return e;
            }
          }),
        };
      })

      // im using addMatcher to manage the asyncthunksMehtod actions like fullfilled,pending,rejected and also to manage the errors loading and error messages and async params
      .addMatcher(
        // isAsyncThunk will run when the action is an asyncthunk exists from giver asycntthunks
        isAnyOf(
          // reduxToolKitCaseBuilder helper make fullfilled, pending, and rejected cases
          ...reduxToolKitCaseBuilder([
            getProjectAsyncThunk,
            assignProjectToUsersAsyncThunk,
            unAssignProjectToUsersAsyncThunk,
            createProjectAsyncThunk,
            getProjectsAsyncThunk,
            deleteProjectAsyncThunk,
            getWorkSpaceAsyncThunk,
            getWorkSpaceStaffAsyncThunk,
          ])
        ),
        handleLoadingErrorParamsForAsycThunk
      );
  },
});

export default userSlice.reducer;
export const {
  setLoading,
  setSearchValue,
  setCategoryValue,
  setOrderValue,
  storeUserIds,
} = userSlice.actions;
