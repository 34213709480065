import { createSlice, createAsyncThunk, isAnyOf } from "@reduxjs/toolkit";
import { ApiRequests } from "../../service/ApiRequests";
import {
  catchAsync,
  detectError,
  handleLoadingErrorParamsForAsycThunk,
  reduxToolKitCaseBuilder,
} from "../../helpers/detectError";
import { toast } from "react-toastify";
// import { useNavigate } from "react-router-dom";
// import { red } from "@mui/material/colors";

// Start User Slices
///////////////////////////////////////////////////
export const createClintAsyncThunk = createAsyncThunk(
  "user/createClintAsyncThunk",
  catchAsync(async ({ data, callBack }, { dispatch, getState }) => {
    const response = await ApiRequests.createClint(data);
    // if (response.status === 201) {
    //   toast.success("Document Created Successfully!");
    // }
    if (callBack) await callBack();
    return response?.data;
  })
);

export const getClientsAsyncThunk = createAsyncThunk(
  "user/getClientsAsyncThunk",
  catchAsync(async ({ params, }, { dispatch, getState }) => {
    const response = await ApiRequests.getClints(params,);
    return response?.data;
  })
);

export const getClintAsyncThunk = createAsyncThunk(
  "user/getClintAsyncThunk",
  catchAsync(async ({ id }, { dispatch, getState }) => {
    const response = await ApiRequests.getClint(id);
    return response?.data;
  })
);

export const editClintAsyncThunk = createAsyncThunk(
  "user/editClintAsyncThunk",
  catchAsync(async ({ id, data, callBack }, { dispatch, getState }) => {
    const response = await ApiRequests.updateClint({ id, data });
    // if (response.status === 200) {
    // toast.success("Project Updated Successfully!");
    // }
    if (callBack) await callBack();

    return response?.data;
  })
);

export const deleteClintAsyncThunk = createAsyncThunk(
  "user/deleteClintAsyncThunk",
  catchAsync(async ({ id, callBack }, { dispatch, getState }) => {
    const response = await ApiRequests.deleteClint(id);
    if (response.status === 204) {
      toast.success("Clint Deleted Successfully!");
    }
    if (callBack) await callBack();
    return id;
  })
);

///////////////////////////////////////////////////

const initialState = {
  //news states
  client: {
    page: 0,
    results: [],
    totalPages: 1,
  },
  clint: {},
  story: null,
  assets: null,
  asset: null,
  listings: {
    page: 0,
    results: [],
    totalPages: 1,
  },
  // manager states
  errors: {},
  loadings: {},
  errorMessages: {},
  errorCodes: {},
  paramsForThunk: {},
  search: null,
  categoryId: null,
  categories: [],
  order: "asce",
  userIds: [],
};

const userSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    setSearchValue(state, action) {
      state.search = action.payload;
    },
    setCategoryValue(state, action) {
      state.categoryId = action.payload;
    },
    setOrderValue(state, action) {
      state.order = action.payload;
    },
    storeUserIds: (state, action) => {
      state.userIds = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      //
      .addCase(getClientsAsyncThunk.fulfilled, (state, action) => {
        state.client = action.payload;
      })


      // im using addMatcher to manage the asyncthunksMehtod actions like fullfilled,pending,rejected and also to manage the errors loading and error messages and async params
      .addMatcher(
        // isAsyncThunk will run when the action is an asyncthunk exists from giver asycntthunks
        isAnyOf(
          // reduxToolKitCaseBuilder helper make fullfilled, pending, and rejected cases
          ...reduxToolKitCaseBuilder([
            createClintAsyncThunk,
            getClientsAsyncThunk,
            getClintAsyncThunk,
            editClintAsyncThunk,
          ])
        ),
        handleLoadingErrorParamsForAsycThunk
      );
  },
});

export default userSlice.reducer;
export const {
  setLoading,
  setSearchValue,
  setCategoryValue,
  setOrderValue,
  storeUserIds,
} = userSlice.actions;
